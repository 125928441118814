/****************************************

TAB SECTION
Basic show/hide content area with navigation

include js: 
tabSections()

basic structure: 
<div class="tab-section" data-tab-section>
    <div class="tab-nav">
        <div class="tab-link active" data-tab-target="0">Tab 0</div>
        <div class="tab-link" data-tab-target="1">Tab 1</div>
    </div>
    <div class="tab-cont">
        <div class="tab-item active" data-tab="0">Tab 0 Content</div>
        <div class="tab-item" data-tab="1">Tab 1 Content</div>
    </div>
</div>

optional mobile:
add "mobile-active" to tab deired to be open on mobile 

****************************************/

.tab-section {
	.tab-nav {
		@include flexCenter;
		@include internalNav;
		position: relative;

		.tab-link {
			@include internalNavLink;
			padding: 10px;
			cursor: pointer;

			&.active {
				text-decoration: underline;
			}
		}
	}

	.tab-select {
		width: 100%;
		padding: 10px;
		font-size: 1.6rem;
		border-radius: 0;
		display: none;
		color: color(black);

		@media (max-width: bp(nav)) {
			display: block;
		}
	}

	.tab-cont {
		position: relative;
		width: 100%;

		.tab-item {
			@include hideLayer;
			top: 0;
			transition: opacity $animationDuration;

			&.active {
				@include showLayer;
			}

			@media (max-width: bp(sm)) {
				&.mobile-active {
					@include showLayer;
				}
			}
		}
	}
}
