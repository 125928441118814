/****************************************

accordion SECTION

include js: 
acccordianSection()

optional setting:
data-accordion="solo" : show one item only

basic structure: 
<div class="accordion-section" data-accordion="solo">
    <div class="accordion-item" data-accordion-item>
        <div class="accordion-header" data-accordion-header>
            <div class="accordion-icon">
                <span data-accordion-open>+</span>
                <span data-accordion-close>-</span>
            </div>
            accordion A
        </div>
        <div class="accordion-details">
            <p>accordion A - Content</p>
        </div>
    </div>
  </div>
</div>

****************************************/

.accordion-section {
	.accordion-item {
		.accordion-header {
			display: flex;
			align-items: center;
			min-height: 30px;
			cursor: pointer;
			border-bottom: 1px solid #000;

			p {
				margin-bottom: 0;
			}

			.accordion-icon {
				min-width: 15px;
				margin-right: 5px;
				display: flex;

				span[data-accordion-open] {
					@include showLayer;

					line-height: 1;
					text-align: center;
				}

				span[data-accordion-close] {
					@include hideLayer;

					line-height: 1;
					text-align: center;
				}
			}
		}

		.accordion-details {
			width: 100%;
			max-height: 0px;
			overflow: hidden;
			padding: 0 10px;

			transition: all 0.5s;

			p {
				min-height: 100px;
			}
		}

		&.open {
			.accordion-header .accordion-icon {
				span[data-accordion-open] {
					@include hideLayer;
				}

				span[data-accordion-close] {
					@include showLayer;
				}
			}

			.accordion-details {
				max-height: 500px;
				padding: 20px 10px;
			}
		}
	}
}
