/****************************************

SWIPER SECTION

include js: 

basic structure: 

****************************************/

.swiper-section {
	position: relative;
	width: 100%;
	height: auto;

	.swiper-nav {
		@include flexCenter;
		@include internalNav;
		position: relative;

		.swiper-link {
			@include internalNavLink;
			padding: 10px;
			cursor: pointer;

			&.active {
				text-decoration: underline;
			}
		}
	}

	.swiper-cont {
		overflow: hidden;
		position: relative;
	}

	.swiper-button-prev {
		background: none;
		width: 50px;
		height: 50px;
		margin: 0;
		top: calc((100% - 50px) / 2);
		left: 5px;

		&:after {
			content: "";
			width: 20px;
			height: 20px;
			border-top: 1px solid #000;
			border-right: 1px solid #000;
			position: absolute;
			top: 13px;
			left: 13px;
			transform: rotate(-135deg);
		}
	}

	.swiper-button-next {
		background: none;
		width: 50px;
		height: 50px;
		margin: 0;
		top: calc((100% - 50px) / 2);
		right: 5px;

		&:after {
			content: "";
			width: 20px;
			height: 20px;
			border-top: 1px solid #000;
			border-right: 1px solid #000;
			position: absolute;
			top: 13px;
			right: 13px;
			transform: rotate(45deg);
		}
	}

	&.light {
		.swiper-button-next:after,
		.swiper-button-prev:after {
			border-color: #fff;
		}
	}

	.swiper-button-next.swiper-button-disabled,
	.swiper-button-prev.swiper-button-disabled {
		opacity: 0.5;
	}

	.swiper-pagination {
		width: 100%;
		position: relative !important;
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 45px;

		.swiper-pagination-bullet {
			margin: 0 5px;
			width: 17px;
			height: 17px;
			opacity: 1;
			background-color: rgba(255, 255, 255, 0);
			border: 3px solid #fff;
			transition: all 0.5s;

			&.swiper-pagination-bullet-active {
				background-color: #fff;
				border: 3px solid #fff;
				opacity: 1;
				width: 17px;
				height: 17px;
			}
		}
	}

	&.inset {
		padding: 0 50px;
	}
}

/****************************************

SITE SPECIFIC

****************************************/

.swiper-slide {
}

.swiper-section {
	.swiper-button-prev,
	.swiper-button-next {
		background-image: url("../images/swiper-arrow.png");
		background-size: 100% auto;
		width: 40px;
		height: 40px;
		position: absolute;
		margin: 0;
		left: 20px;
		top: calc(100% - 130px);
		transition: transform $animationDuration;

		@media (max-width: bp(nav)) {
			top: calc(100% - 170px);
			left: 10px;
		}

		&:hover {
			transform: scale(1.2);
		}

		&:after {
			display: none;
		}
	}

	.swiper-button-next {
		transform: rotate(180deg);
		right: 20px;
		left: auto;

		@media (max-width: bp(nav)) {
			right: 10px;
		}

		&:hover {
			transform: rotate(180deg) scale(1.2);
		}
	}

	&.inset {
		.swiper-button-prev {
			background: none;
			width: 50px;
			height: 50px;
			margin: 0;
			top: calc((100% - 50px) / 2);
			left: 5px;

			&:after {
				content: "";
				width: 20px;
				height: 20px;
				border-top: 1px solid color(accent) !important;
				border-right: 1px solid color(accent) !important;
				position: absolute;
				top: 13px;
				left: 13px;
				transform: rotate(-135deg);
				display: block !important;
			}
		}

		.swiper-button-next {
			background: none;
			width: 50px;
			height: 50px;
			margin: 0;
			top: calc((100% - 50px) / 2);
			right: 5px;

			&:after {
				content: "";
				width: 20px;
				height: 20px;
				border-top: 1px solid color(accent) !important;
				border-right: 1px solid color(accent) !important;
				position: absolute;
				top: 13px;
				right: 13px;
				transform: rotate(-135deg) !important;
				display: block !important;
			}
		}
	}
}
