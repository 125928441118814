.page-template-showroom-template {
	section {
		background: color(grey);
	}

	.process-section {
		.card {
			background: color(accent);
			position: relative;
			cursor: pointer;
			overflow: hidden;

			.number {
				font-family: $headlineFont;
				font-size: 150px;
				position: absolute;
				bottom: 0;
				left: -5px;
				margin: 0;
				line-height: 0.7;
				color: color(accent);
				transition: opacity $animationDuration;
			}

			.img-cont {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
				background: color(black);
				transition: opacity $animationDuration;

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0.7;
					object-fit: cover;
				}
			}

			.hover {
				padding: $padding--md $padding--sm;

				p {
					color: color(white);
				}
			}

			&:hover {
				.number {
					opacity: 0;
				}
				.img-cont {
					opacity: 0;
				}
			}
		}
	}

	.testimonial-section {
		.open-quote {
			color: color(accent);
			font-size: 120px;
			position: absolute;
			line-height: 0;

			@media (max-width: bp(nav)) {
				position: relative;
				margin: 0 auto;
				display: block;
				width: 100%;
				text-align: center;
			}
		}

		.quote {
			padding-left: 50px;

			@media (max-width: bp(nav)) {
				padding-left: 0;
			}
		}

		p {
			@include h3-font-styles;
			font-family: $bodyFont;
			letter-spacing: 1.5px;
			line-height: 1.8;

			@media (max-width: bp(nav)) {
				text-align: center;
			}
		}

		.author {
			text-align: right;

			@media (max-width: bp(nav)) {
				text-align: center;
			}
		}
	}

	.map-section {
		background: color(white);

		[data-map] {
			width: 100%;
			height: 100%;
			height: 500px;
			margin-bottom: $padding--md;
		}

		.info-window {
			width: 300px;
			//height: 100px;
			padding: 10px;
			border: 2px solid black;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			p {
				margin: 0 0 5px 0;
				line-height: 1.2;
				text-align: center;
			}

			.btn {
				margin: 10px auto;
			}
		}

		.gm-style {
			.gm-style-iw-d {
				overflow: auto !important;
			}

			.gm-style-iw-c {
				padding: 0;
				border-radius: 0;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
			}

			[aria-label="Close"] {
				width: 20px !important;
				height: 20px !important;
				top: 0 !important;
				right: 0 !important;
				display: flex !important;
				align-items: center;
				justify-content: center;
				border: 1px solid black !important;

				span {
					margin: 0 !important;
				}
			}
		}
	}
}
