.page-template-home-template {
	.hero-section {
		height: 100vh;
		max-height: 1080px;
		padding-top: 110px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@media (max-width: bp(nav)) {
			height: 65vh;
		}

		.img {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			margin-bottom: 0;
		}

		.scroll {
			width: 100%;
			color: color(white);
			position: relative;
			padding: $padding--md 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			p {
				text-transform: uppercase;
				color: color(white);
				margin-bottom: 0;
			}

			&:after {
				content: "";
				width: 15px;
				height: 15px;
				border-left: 1px solid color(white);
				border-top: 1px solid color(white);
				display: block;
				transform: rotate(-135deg);
				margin-top: -5px;
			}
		}
	}

	.content-sections {
		background: color(grey);
		padding-bottom: 0;

		p.title2 {
			margin-bottom: $padding--lg;
			font-family: $bodyFont;
			text-align: center;
			letter-spacing: 1.5px;

			@media (max-width: bp(nav)) {
				margin-bottom: $padding--md;
			}
		}

		.section {
			background: color(white);

			&:not(:last-of-type) {
				margin-bottom: $padding--md;
			}

			.column-layout {
				.col {
					&:nth-of-type(1) {
						width: 60%;

						@media (max-width: bp(nav)) {
							width: 100%;
						}

						img {
							width: 100%;
							height: auto;
						}
					}

					&:nth-of-type(2) {
						width: 40%;
						padding: $padding--md;

						text-align: center;

						@media (max-width: bp(nav)) {
							width: 100%;
							padding: $padding--sm;
						}
					}

					&:nth-of-type(2) {
						position: relative;
						align-items: center;
						justify-content: center;
					}
				}
			}

			&:nth-of-type(even) {
				.column-layout {
					flex-direction: row-reverse;

					@media (max-width: bp(nav)) {
						flex-direction: column;
					}

					.col {
						&:nth-of-type(2) {
						}
					}
				}
			}
		}
	}

	.about-section {
		background: color(grey);

		h2 {
			text-align: center;
			font-family: $bodyFont;
			letter-spacing: 1.5px;
		}

		p {
			a {
				color: color(accent);
				text-decoration: none;
			}
		}
	}
}
