/****************************************

VARIABLES

****************************************/
@import "_functions";

// MSC
$animationDuration: 0.5s;

//fonts
@import url("https://use.typekit.net/hzk0zyv.css");

$headlineFont: "le-monde-livre-std", sans-serif;
$bodyFont: "articulat-cf", sans-serif;

//font sizes
$fontSizes: (
	h1: 6rem,
	h2: 5rem,
	h3: 4rem,
	h4: 3rem,
	h5: 2rem,
	p: 1.8rem,
	eyebrow: 1.4rem,
	disclaimer: 1.4rem,
	nav: 1.5rem,
	xlform: 5.2rem,
);

//break points
$breakPoints: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	nav: 992px,
	tablet: 1024px,
	sm-desktop: 1200px,
	lg-desktop: 1400px,
);

$mobile: bp(nav);

//Spacing
$padding--xs: 1rem;
$padding--sm: 2rem;
$padding--md: 4rem;
$padding--lg: 8rem;
$paddingMain: $padding--sm;
$paddingMainMobile: 2rem;
$gap: 1rem;

$borderRadius: 10px;

$elementSpacingDesktop: 2rem;
@mixin elementSpacing {
	margin-bottom: $elementSpacingDesktop;
}

@mixin paddingAllSides {
	padding: $padding--lg;

	@media (max-width: bp(nav)) {
		padding: $paddingMainMobile;
	}
}

@mixin paddingAllSidesSm {
	padding: $padding--sm;

	@media (max-width: bp(nav)) {
		//padding: $paddingMainMobile;
	}
}

@mixin paddingAllSidesXs {
	padding: $padding--xs;
}

@mixin paddingTopBottom {
	padding: $padding--lg 0;

	@media (max-width: bp(nav)) {
		padding: #{$padding--sm * 2} 0;
	}
}

@mixin paddingTopBottomMd {
	padding: #{$padding--lg/2} 0;

	@media (max-width: bp(nav)) {
		padding: $paddingMainMobile 0;
	}
}

@mixin paddingTopBottomSm {
	padding: $padding--sm 0;

	@media (max-width: bp(nav)) {
		//padding: $paddingMainMobile 0;
	}
}

@mixin paddingTopBottomXs {
	padding: $padding--xs 0;
}

//colors
$colors: (
	white: (
		base: #fff,
		transparent: rgba(255, 255, 255, 0.25),
	),
	black: (
		base: #000,
	),
	grey: (
		base: #f3f3f3,
		light: #eee,
		dark: #bfbfbf,
	),
	accent: (
		base: #cda96e,
	),
);

$grad: linear-gradient(90deg, #0e4486 0%, #246fc7 100%);
