/****************************************

FUNCTIONS

****************************************/
/****************************************

VARIABLES

****************************************/
/****************************************

FUNCTIONS

****************************************/
@import url("https://use.typekit.net/hzk0zyv.css");
/****************************************

GLOBAL

****************************************/
/****************************************
REBASE
****************************************/
.debug-title {
  display: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: #000;
  font-family: "articulat-cf", sans-serif;
  background-color: #fff;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  color: #000;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
main section {
  padding: 8rem 0;
}
@media (max-width: 992px) {
  main section {
    padding: 4rem 0;
  }
}

/****************************************
Responsive switches
****************************************/
.desktop {
  display: block;
}
@media (max-width: 992px) {
  .desktop {
    display: none !important;
  }
}

.mobile {
  display: none;
}
@media (max-width: 992px) {
  .mobile {
    display: block;
  }
}

/****************************************
Structural components
****************************************/
section,
.full {
  width: 100%;
  position: relative;
}

/****************************************
Responsive video frame
****************************************/
.responsive-video {
  width: 100%;
}
.responsive-video .video-size-frame {
  height: 0;
  padding-bottom: 56.3%;
  position: relative;
  overflow: hidden;
}
.responsive-video .video-size-frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/****************************************
Image crop
****************************************/
.img-crop {
  width: 100%;
  padding-bottom: 66.7%;
  position: relative;
  overflow: hidden;
}
.img-crop.two-three {
  padding-bottom: 66.7%;
}
.img-crop.sixteen-nine {
  padding-bottom: 56.3%;
}
.img-crop.square {
  padding-bottom: 100%;
}
.img-crop img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.img-crop.contain img {
  object-fit: contain;
}

/****************************************
quick show/hide layers
****************************************/
/****************************************
flex layouts
****************************************/
.flex {
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .flex-center {
    flex-direction: column;
  }
}
.flex-center .col {
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
}

/****************************************
ui basics
****************************************/
/****************************************
UTILITY CLASSES
****************************************/
.relative {
  position: relative;
}

/****************************************
BACKGROUNDS
****************************************/
.bg-fill {
  background-size: cover;
  background-position: center center;
}

.bg-reveal {
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

/****************************************

GLOBAL

****************************************/
.social {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .social {
    margin-bottom: 1rem;
  }
}
.social li {
  margin: 0 0 0 0.5rem;
}
.social li a {
  width: 25px;
  height: auto;
  display: flex;
  align-items: center;
}
.social li a img {
  width: 100%;
  height: auto;
}

/****************************************
NINJAFORMS REBASE
****************************************/
.form-cont {
  margin: 30px 0;
}
.form-cont .nf-form-content {
  padding: none;
  max-width: auto;
}
.form-cont h3 {
  display: none;
}
.form-cont nf-field {
  width: 100%;
  float: left;
  margin: 0 10px 10px 0;
}
.form-cont nf-field .nf-field-container {
  margin-bottom: 0;
}
.form-cont nf-field input,
.form-cont nf-field textarea {
  padding: 10px;
  float: left;
  width: 100%;
  height: auto;
}
.form-cont .nf-form-content input[type=button] {
  background-color: #cda96e;
}
.form-cont .nf-form-fields-required {
  display: none;
}

.hero-section {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.hero-section .img {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: bottom center;
  z-index: 0;
}
@media (max-width: 992px) {
  .hero-section .col:nth-of-type(1) {
    margin-bottom: 4rem;
  }
}
.hero-section .cont--lg,
.hero-section .cont {
  z-index: 9;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.builder-hero-section {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.builder-hero-section .img {
  width: 100%;
  height: 650px;
  background-size: cover;
  background-position: bottom center;
  z-index: 0;
}
@media (max-width: 992px) {
  .builder-hero-section .img {
    height: calc(100vh - 100px);
  }
}
@media (max-width: 992px) {
  .builder-hero-section .col:nth-of-type(1) {
    margin-bottom: 4rem;
  }
}
.builder-hero-section .cont--lg,
.builder-hero-section .cont {
  z-index: 9;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.close {
  position: fixed;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  font-size: 36px;
  line-height: 0;
  display: none;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #000;
  z-index: 99;
}
@media (max-width: 992px) {
  .close {
    display: flex;
  }
}

/****************************************

TYPOGRPAHY

****************************************/
h1,
.title1 {
  font-family: "le-monde-livre-std", sans-serif;
  font-weight: 100;
  font-size: 5.5rem;
  line-height: 0.9166666667;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  h1,
  .title1 {
    font-size: 4.5rem;
  }
}

h2,
.title2 {
  font-family: "le-monde-livre-std", sans-serif;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 1.3714285714;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  h2,
  .title2 {
    font-size: 2.5rem;
  }
}

h3,
.title3 {
  font-family: "le-monde-livre-std", sans-serif;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  h3,
  .title3 {
    font-size: 2.2rem;
  }
}

h4,
.title4 {
  font-family: "le-monde-livre-std", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  color: #000;
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  h4,
  .title4 {
    font-size: 1.8rem;
  }
}

h5,
.title5 {
  font-family: "le-monde-livre-std", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  color: #000;
  margin-bottom: 2rem;
}

h6,
.h6,
.title6 {
  font-family: "le-monde-livre-std", sans-serif;
}

.small-headline {
  text-transform: uppercase;
  color: #cda96e;
  letter-spacing: 0.15rem;
  font-weight: 600;
}

.desc p strong {
  color: #cda96e;
}

p,
ul,
ol {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 1px;
  line-height: 2;
  color: #000;
  margin-bottom: 2rem;
}
p sup,
ul sup,
ol sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
p.disclaimer,
ul.disclaimer,
ol.disclaimer {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
p.eyebrow,
ul.eyebrow,
ol.eyebrow {
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1;
}
p.solo-eyebrow,
ul.solo-eyebrow,
ol.solo-eyebrow {
  text-transform: uppercase;
  color: #000;
}
p a,
ul a,
ol a {
  color: #000;
  text-decoration: underline;
}

ol {
  padding-left: 1em;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
ul li {
  margin: 0;
  padding: 0;
}
ul.bullets {
  position: relative;
  margin-bottom: 2rem;
}
ul.bullets li {
  position: relative;
  padding: 0 0 0 1.5em;
}
ul.bullets li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  top: 0.8em;
  left: 0;
}

.strong,
strong {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.t-accent {
  color: #cda96e;
}

.t-body {
  font-family: "articulat-cf", sans-serif;
}

.t-center {
  text-align: center;
}

.desc ul li {
  position: relative;
  padding: 0 0 0 1.5em;
}
.desc ul li:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  top: 0.8em;
  left: 0;
}

/****************************************

BUTTONS

****************************************/
.btn {
  padding: 10px 25px;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  background-color: #cda96e;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn:hover:after {
  transform: translate(5px, 0px);
}
.btn.center {
  margin: 0 auto;
  display: table;
  width: auto;
}
.btn.video {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn.video:after {
  content: "";
  width: 0;
  height: 0;
  display: block;
  margin-left: 7px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 10px solid #fff;
}
.btn.secondary {
  border: 1px solid;
  color: #fff;
}
.btn.secondary:hover {
  border: 1px solid #cda96e;
  background-color: #cda96e;
  color: #fff;
}
.btn.secondary.disabled {
  pointer-events: none;
  border: none;
}
.btn.secondary.apply {
  border-radius: 3px;
  padding: 5px 10px 7px 10px;
}
.btn.secondary.large {
  padding: 15px 25px;
}
.btn.tertiary {
  color: #000;
  background-color: #cda96e;
}

.underline-link {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 1px;
  line-height: 2;
  color: #000;
  margin-bottom: 2rem;
  text-decoration: underline;
  line-height: 1.5rem;
  color: #cda96e;
}
.underline-link sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
.underline-link.disclaimer {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.underline-link.eyebrow {
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1;
}
.underline-link.solo-eyebrow {
  text-transform: uppercase;
  color: #000;
}
.underline-link a {
  color: #000;
  text-decoration: underline;
}

.links {
  display: flex;
  align-items: center;
}
.links *:not(:last-of-type) {
  margin-right: 30px;
}

.video-btn {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  text-decoration: none;
  color: #000;
}
.video-btn img {
  width: 30px;
  height: auto;
  margin-left: 5px;
  transition: transform 0.5s;
}
.video-btn:hover img {
  transform: translate(5px, 0);
}

/****************************************

CONTAINERS

****************************************/
.cont--lg {
  max-width: 1400px;
  margin: 0 auto;
}
@media (max-width: 1450px) {
  .cont--lg {
    padding: 0 2rem;
  }
}
@media (max-width: 1450px) {
  .cont--lg.no-padding {
    padding: 0;
  }
}

.cont {
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1250px) {
  .cont {
    padding: 0 2rem;
  }
}

.cont--sm {
  max-width: 875px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .cont--sm {
    padding: 0 2rem;
  }
}

/****************************************

LAYOUTS

****************************************/
.column-layout {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 992px) {
  .column-layout {
    flex-direction: column;
  }
}
.column-layout .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc((100% - 8rem) / 2);
}
@media (max-width: 1200px) {
  .column-layout .col {
    width: calc((100% - 2rem) / 2);
  }
}
@media (max-width: 992px) {
  .column-layout .col {
    width: 100%;
  }
}
.column-layout .col--two-thirds {
  width: calc((100% * 2 / 3) - 8rem);
}
@media (max-width: 1200px) {
  .column-layout .col--two-thirds {
    width: calc((100% * 2 / 3) - 2rem);
  }
}
@media (max-width: 992px) {
  .column-layout .col--two-thirds {
    width: 100%;
  }
}
.column-layout .col--third {
  width: 33.3333333333%;
}
@media (max-width: 992px) {
  .column-layout .col--third {
    width: 100%;
  }
}
.column-layout.sm-gap .col {
  width: calc((100% - 4rem) / 2);
}
@media (max-width: 992px) {
  .column-layout.sm-gap .col {
    width: 100%;
  }
}
.column-layout.sticky-right {
  align-items: flex-start;
}
.column-layout.sticky-right .col {
  min-height: calc(100vh - 32px - 2rem - 2rem);
}
.column-layout.sticky-right .col:first-of-type {
  min-height: 200vh;
}
.column-layout.sticky-right .col:last-of-type {
  position: sticky;
  top: calc(32px + 2rem);
}
.column-layout.sticky-left {
  align-items: flex-start;
}
.column-layout.sticky-left .col {
  min-height: calc(100vh - 32px - 2rem - 2rem);
}
.column-layout.sticky-left .col:first-of-type {
  position: sticky;
  top: calc(32px + 2rem);
}
@media (max-width: 992px) {
  .column-layout.sticky-left .col:first-of-type {
    position: relative;
  }
}
.column-layout.sticky-left .col:last-of-type {
  min-height: 200vh;
}

.card-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  width: 100%;
}
.card-layout.halves .card {
  width: calc((100% - 2rem) / 2);
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .card-layout.halves .card {
    width: calc((100% - 2rem) / 2);
    margin-bottom: 2rem;
  }
}
@media (max-width: 992px) {
  .card-layout.halves .card {
    width: 100%;
  }
}
.card-layout.halves .card:not(:nth-of-type(even)) {
  margin-right: 2rem;
}
@media (max-width: 1200px) {
  .card-layout.halves .card:not(:nth-of-type(even)) {
    margin-right: 2rem;
  }
}
@media (max-width: 992px) {
  .card-layout.halves .card:not(:nth-of-type(even)) {
    margin-right: 0;
  }
}
.card-layout.halves .card:last-of-type {
  margin-right: 0;
}
.card-layout.thirds .card {
  width: calc((100% - (1rem * 4)) / 3);
  margin-bottom: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.thirds .card {
    width: 100%;
  }
}
.card-layout.thirds .card:not(:nth-of-type(3n + 3)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.thirds .card:not(:nth-of-type(3n + 3)) {
    margin-right: 0;
  }
}
.card-layout.thirds .card:last-of-type {
  margin-right: 0;
}
.card-layout.quarters .card {
  width: calc((100% - (1rem * 6)) / 4);
  margin-bottom: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.quarters .card {
    width: 100%;
  }
}
.card-layout.quarters .card:not(:nth-of-type(4n + 4)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.quarters .card:not(:nth-of-type(4n + 4)) {
    margin-right: 0;
  }
}
.card-layout.quarters .card:last-of-type {
  margin-right: 0;
}
.card-layout.fifths .card {
  width: calc((100% - (1rem * 8)) / 5);
  margin-bottom: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.fifths .card {
    width: 100%;
  }
}
.card-layout.fifths .card:not(:nth-of-type(5n + 6)) {
  margin-right: calc(1rem * 2);
}
@media (max-width: 992px) {
  .card-layout.fifths .card:not(:nth-of-type(5n + 6)) {
    margin-right: 0;
  }
}
.card-layout.fifths .card:last-of-type {
  margin-right: 0;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 1200px) {
  .grid-layout {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (max-width: 1024px) {
  .grid-layout {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
@media (max-width: 992px) {
  .grid-layout {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
.grid-layout .card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
}
.grid-layout .desc {
  display: none;
}

.wrap-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrap-layout .col,
.wrap-layout .card,
.wrap-layout .field,
.wrap-layout .group {
  width: calc((100% - 2rem) / 2);
  margin-bottom: 2rem;
}
@media (max-width: 1200px) {
  .wrap-layout .col,
  .wrap-layout .card,
  .wrap-layout .field,
  .wrap-layout .group {
    width: calc((100% - 2rem) / 2);
  }
}
@media (max-width: 992px) {
  .wrap-layout .col,
  .wrap-layout .card,
  .wrap-layout .field,
  .wrap-layout .group {
    width: 100%;
  }
}
.wrap-layout .group .card,
.wrap-layout .group .field {
  width: 100%;
  margin: 0 0 5px 0;
}

/****************************************

FIXED HEADER

****************************************/
@media (max-width: 576px) {
  #wpadminbar {
    display: none !important;
  }
}

@media (max-width: 576px) {
  html {
    margin-top: 0 !important;
  }
}

header {
  width: 100vw;
  z-index: 999;
  position: fixed;
  background: #fff;
  transition: all 0.5s;
}
.logged-in header {
  top: 32px;
}
@media (max-width: 576px) {
  .logged-in header {
    top: 45px;
  }
  .logged-in header.scroll {
    top: 0;
  }
}

header .logo {
  width: auto;
  display: block;
  margin: 0;
  text-decoration: none;
}
@media (max-width: 992px) {
  header .logo {
    padding-left: 0;
  }
}
header .logo img {
  height: 45px;
  width: auto;
}
@media (max-width: 992px) {
  header .logo img {
    height: 45px;
  }
}
header .logo img.white-logo {
  display: none;
}
header .logo p {
  text-decoration: none;
  font-weight: bold;
  color: #cda96e;
  text-align: center;
  margin: 0;
  font-size: 18px;
}
@media (max-width: 992px) {
  header .logo p {
    font-size: 13px;
  }
}
header .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 98;
  padding: 5px 0;
}
@media (max-width: 1280px) {
  header .inner {
    padding-right: 30px;
  }
}
@media (max-width: 992px) {
  header .inner {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  header .lrg-cont {
    width: 100%;
    margin: 0;
  }
}
header .menu-toggle {
  width: 50px;
  height: 50px;
  padding: 10px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: #000;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.5s;
}
header .menu-toggle .line {
  border-bottom: 2px solid #fff;
  width: 20px;
  height: 1px;
  position: relative;
  display: block;
  transition: all 0.5s;
}
header.active .menu-toggle {
  justify-content: center;
}
header.active .menu-toggle .line:nth-of-type(3) {
  opacity: 0;
}
header.active .menu-toggle .line:nth-of-type(1) {
  transform: rotate(45deg);
}
header.active .menu-toggle .line:nth-of-type(2) {
  transform: rotate(-45deg) translate(1px, -2px);
}
header.active nav {
  width: 100vw;
  right: 0;
}
header.scroll nav {
  background: rgba(255, 255, 255, 0.95) !important;
}
header nav {
  width: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.95) !important;
  transition: width 0.5s, padding 0.5s, right 0.75s;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  right: -100vw;
  background: #fff;
}
@media (max-width: 992px) {
  header nav {
    padding-top: 50px;
  }
}
header nav .menu {
  width: 100vw;
  max-height: calc(100vh - 100px);
  overflow: scroll;
  padding: 5px 10px 5px 175px;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  padding: 5px 10px;
  max-width: 500px;
  margin: 0 auto;
}
header nav .menu li {
  margin: 0;
}
header nav .menu li:not(:last-of-type) {
  margin-bottom: 10px;
}
header nav .menu li a {
  font-family: "le-monde-livre-std", sans-serif;
  font-weight: 400;
  font-size: 3.5rem;
  line-height: 1.3714285714;
  color: #000;
  margin-bottom: 2rem;
  margin: 0;
  color: #000;
  text-decoration: none;
  font-family: "le-monde-livre-std", sans-serif;
  padding: 12px 10px;
  min-height: 59px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: auto;
  position: relative;
  font-weight: 100;
  transition: color 0.5s;
  min-height: inherit;
  padding: 5px 10px;
}
@media (max-width: 992px) {
  header nav .menu li a {
    font-size: 2.5rem;
  }
}
header nav .menu li a:after {
  position: absolute;
  height: 1px;
  bottom: 10px;
  left: 50%;
  width: 0;
  content: "";
  display: block;
  background: #000;
  transition: left 0.5s, width 0.5s;
}
header nav .menu li a:hover:after {
  left: 25%;
  width: 50%;
}
header nav .menu li a.active {
  color: #cda96e;
}
header nav .menu li.current_page_item a {
  color: #cda96e;
}
header nav .menu li.current_page_item ul li a {
  color: #000;
}
header nav .menu li.current_page_item ul li.current_page_item a {
  color: #cda96e;
}
header nav .menu li.menu-item-has-children {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
header nav .menu li.menu-item-has-children a {
  order: -1;
}
header nav .menu li.menu-item-has-children .toggle-menu {
  content: "";
  width: 15px;
  height: 15px;
  border-right: 2px solid #000;
  border-top: 2px solid #000;
  transform: rotate(135deg) translate(-5px, 0);
  transition: transform 0.5s;
  display: block;
  margin-left: 15px;
  cursor: pointer;
}
header nav .menu li.menu-item-has-children ul {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}
header nav .menu li.menu-item-has-children.active > .toggle-menu {
  transform: rotate(-45deg) translate(0px, 10px);
}
header nav .menu li.menu-item-has-children.active ul {
  max-height: 1000px;
}
header .highlight-menu {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  header .highlight-menu {
    width: 100%;
    flex-direction: column;
    display: none;
  }
  header .highlight-menu .menu-highlight-menu-container {
    width: 100%;
  }
}
header .highlight-menu .current {
  display: none;
  text-align: right;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  text-decoration: none;
  letter-spacing: 1.5px;
}
header .highlight-menu ul {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  header .highlight-menu ul {
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }
}
header .highlight-menu ul a {
  text-transform: uppercase;
  padding: 0 15px;
  text-decoration: none;
  letter-spacing: 1.5px;
}
@media (max-width: 992px) {
  header .highlight-menu ul a {
    width: 100%;
    text-align: right;
    padding-right: 0;
  }
}
header .highlight-menu ul li.current_page_item a,
header .highlight-menu ul li.current-page-ancestor a {
  text-decoration: underline;
}
header .highlight-menu ul li.current_page_item ul li a,
header .highlight-menu ul li.current-page-ancestor ul li a {
  text-decoration: none;
}
header .highlight-menu ul li.current_page_item ul li.current_page_item a,
header .highlight-menu ul li.current-page-ancestor ul li.current_page_item a {
  text-decoration: underline;
}
header .highlight-menu ul li.menu-item-has-children {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 1440px) {
  header .highlight-menu ul li.menu-item-has-children {
    position: inherit;
  }
}
header .highlight-menu ul li.menu-item-has-children ul {
  display: none !important;
  width: 200px;
  position: absolute;
  top: 25px;
  left: -10px;
  width: 280px;
  background: white;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, padding 0.5s;
}
@media (max-width: 1440px) {
  header .highlight-menu ul li.menu-item-has-children ul {
    left: auto;
    right: 0;
    top: 45px;
  }
}
header .highlight-menu ul li.menu-item-has-children.active > li:after, header .highlight-menu ul li.menu-item-has-children:hover > li:after {
  transform: rotate(-45deg) translate(0px, 10px);
}
header .highlight-menu ul li.menu-item-has-children.active ul, header .highlight-menu ul li.menu-item-has-children:hover ul {
  max-height: 1000px;
  padding: 10px;
}
header .highlight-menu.active ul {
  max-height: 500px;
}

@media (max-width: 992px) {
  body.scroll {
    padding-top: 0;
  }
}
body.scroll header {
  backdrop-filter: blur(5px);
}

.header-spacing {
  width: 100%;
  height: 59px;
}
@media (max-width: 992px) {
  .header-spacing {
    height: 60px;
  }
}

/****************************************

FOOTER

****************************************/
footer {
  padding: 4rem 0;
}
@media (max-width: 992px) {
  footer {
    padding: 2rem 0;
  }
}
footer .logo {
  margin-bottom: 8rem;
}
@media (max-width: 992px) {
  footer .logo {
    margin-bottom: 2rem;
  }
}
footer .column-layout.inital .col {
  width: 100%;
  width: 225px;
  flex: none;
}
@media (max-width: 992px) {
  footer .column-layout.inital .col {
    margin-bottom: 4rem;
  }
}
footer .column-layout.inital .col:nth-of-type(1) {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex: none;
}
footer .column-layout.inital .col a {
  text-decoration: none;
}
footer .column-layout.inital .col p {
  margin: 0;
  line-height: 1.4;
}
footer .column-layout.inital .col p.title {
  font-weight: bold;
  margin-bottom: 5px;
}
footer .column-layout.inital .col:last-of-type {
  width: 100%;
  flex: 1;
}
@media (max-width: 992px) {
  footer .column-layout.inital .col:last-of-type {
    width: 100%;
  }
}
footer p.disclaimer {
  color: #cda96e;
}
footer .nf-form-cont {
  display: flex;
  flex-direction: column;
}
footer .nf-form-cont .nf-form-title h3 {
  font-family: "articulat-cf", sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
}
footer .nf-form-cont .nf-form-wrap {
  width: 100%;
}
footer .nf-form-cont .nf-form-wrap .nf-before-form-content {
  display: none;
}
footer .nf-form-cont .nf-form-wrap .nf-form-content {
  padding: 0;
}
footer .nf-form-cont .nf-form-wrap nf-fields-wrap {
  display: flex;
  flex-wrap: wrap;
}
footer .nf-form-cont .nf-form-wrap nf-field {
  width: calc((100% - 2rem) / 3);
  display: flex;
}
@media (max-width: 992px) {
  footer .nf-form-cont .nf-form-wrap nf-field {
    width: 100%;
  }
}
footer .nf-form-cont .nf-form-wrap nf-field:not(:nth-child(3n+3)) {
  margin-right: 1rem;
}
@media (max-width: 992px) {
  footer .nf-form-cont .nf-form-wrap nf-field:not(:nth-child(3n+3)) {
    margin-right: 0;
  }
}
footer .nf-form-cont .nf-form-wrap nf-field .nf-field-container {
  width: 100%;
  margin-bottom: 1rem;
}
footer .nf-form-cont .nf-form-wrap nf-field input,
footer .nf-form-cont .nf-form-wrap nf-field textarea {
  border: none;
  background: #f3f3f3;
  letter-spacing: 1.5px;
  width: 100%;
  font-family: "articulat-cf", sans-serif;
}
footer .nf-form-cont .nf-form-wrap nf-field textarea {
  height: 100px;
  font-family: "articulat-cf", sans-serif;
}
footer .nf-form-cont .nf-form-wrap nf-field .submit-container input {
  background: #fff;
  color: #cda96e;
  text-align: right;
  height: auto;
  padding: 0;
}
footer .column-layout.update {
  align-items: center;
}
footer .column-layout.update .col {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}
footer .column-layout.update .col:nth-of-type(1), footer .column-layout.update .col:nth-of-type(2), footer .column-layout.update .col:nth-of-type(3) {
  width: 225px;
  flex: none;
}
footer .column-layout.update .col:nth-of-type(4) {
  align-items: center;
}
footer .column-layout.update .col:nth-of-type(4) a {
  font-size: 2rem;
}
footer .column-layout.update .col:nth-of-type(5) {
  width: 200px;
  flex: none;
  align-items: center;
}
footer .column-layout.update .col:nth-of-type(5) .social {
  margin-bottom: 2rem;
}
@media (max-width: 992px) {
  footer .column-layout.update .col {
    margin-bottom: 4rem;
  }
}
footer .column-layout.update .col .logo {
  margin: 0;
}
footer .column-layout.update .col a {
  text-decoration: none;
}
footer .column-layout.update .col a.btn {
  text-transform: uppercase;
}
footer .column-layout.update .col p {
  margin: 0;
  line-height: 1.4;
}
footer .column-layout.update .col p.title {
  font-weight: bold;
  margin-bottom: 5px;
}

#get-in-touch .nf-form-cont {
  display: flex;
  flex-direction: column;
}
#get-in-touch .nf-form-cont .nf-form-title h3 {
  font-family: "articulat-cf", sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
}
#get-in-touch .nf-form-cont .nf-form-wrap {
  width: 100%;
}
#get-in-touch .nf-form-cont .nf-form-wrap .nf-before-form-content {
  display: none;
}
#get-in-touch .nf-form-cont .nf-form-wrap .nf-form-content {
  padding: 0;
}
#get-in-touch .nf-form-cont .nf-form-wrap nf-fields-wrap {
  display: flex;
  flex-direction: column;
}
#get-in-touch .nf-form-cont .nf-form-wrap nf-field {
  display: flex;
}
@media (max-width: 992px) {
  #get-in-touch .nf-form-cont .nf-form-wrap nf-field {
    width: 100%;
  }
}
#get-in-touch .nf-form-cont .nf-form-wrap nf-field .nf-field-container {
  width: 100%;
  margin-bottom: 1rem;
}
#get-in-touch .nf-form-cont .nf-form-wrap nf-field input,
#get-in-touch .nf-form-cont .nf-form-wrap nf-field textarea {
  border: none;
  background: #f3f3f3;
  letter-spacing: 1.5px;
  width: 100%;
  font-family: "articulat-cf", sans-serif;
}
#get-in-touch .nf-form-cont .nf-form-wrap nf-field textarea {
  height: 100px;
  font-family: "articulat-cf", sans-serif;
}
#get-in-touch .nf-form-cont .nf-form-wrap nf-field .submit-container input {
  background: #fff;
  color: #cda96e;
  text-align: right;
  height: auto;
  padding: 0;
}

/****************************************

accordion SECTION

include js: 
acccordianSection()

optional setting:
data-accordion="solo" : show one item only

basic structure: 
<div class="accordion-section" data-accordion="solo">
    <div class="accordion-item" data-accordion-item>
        <div class="accordion-header" data-accordion-header>
            <div class="accordion-icon">
                <span data-accordion-open>+</span>
                <span data-accordion-close>-</span>
            </div>
            accordion A
        </div>
        <div class="accordion-details">
            <p>accordion A - Content</p>
        </div>
    </div>
  </div>
</div>

****************************************/
.accordion-section .accordion-item .accordion-header {
  display: flex;
  align-items: center;
  min-height: 30px;
  cursor: pointer;
  border-bottom: 1px solid #000;
}
.accordion-section .accordion-item .accordion-header p {
  margin-bottom: 0;
}
.accordion-section .accordion-item .accordion-header .accordion-icon {
  min-width: 15px;
  margin-right: 5px;
  display: flex;
}
.accordion-section .accordion-item .accordion-header .accordion-icon span[data-accordion-open] {
  position: relative;
  top: auto;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
  line-height: 1;
  text-align: center;
}
.accordion-section .accordion-item .accordion-header .accordion-icon span[data-accordion-close] {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  z-index: -5;
  line-height: 1;
  text-align: center;
}
.accordion-section .accordion-item .accordion-details {
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  padding: 0 10px;
  transition: all 0.5s;
}
.accordion-section .accordion-item .accordion-details p {
  min-height: 100px;
}
.accordion-section .accordion-item.open .accordion-header .accordion-icon span[data-accordion-open] {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  z-index: -5;
}
.accordion-section .accordion-item.open .accordion-header .accordion-icon span[data-accordion-close] {
  position: relative;
  top: auto;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
}
.accordion-section .accordion-item.open .accordion-details {
  max-height: 500px;
  padding: 20px 10px;
}

/****************************************

TAB SECTION
Basic show/hide content area with navigation

include js: 
tabSections()

basic structure: 
<div class="tab-section" data-tab-section>
    <div class="tab-nav">
        <div class="tab-link active" data-tab-target="0">Tab 0</div>
        <div class="tab-link" data-tab-target="1">Tab 1</div>
    </div>
    <div class="tab-cont">
        <div class="tab-item active" data-tab="0">Tab 0 Content</div>
        <div class="tab-item" data-tab="1">Tab 1 Content</div>
    </div>
</div>

optional mobile:
add "mobile-active" to tab deired to be open on mobile 

****************************************/
.tab-section .tab-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 992px) {
  .tab-section .tab-nav {
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .tab-section .tab-nav {
    flex-direction: column;
  }
}
.tab-section .tab-nav .tab-link {
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.tab-section .tab-nav .tab-link.active {
  text-decoration: underline;
}
.tab-section .tab-select {
  width: 100%;
  padding: 10px;
  font-size: 1.6rem;
  border-radius: 0;
  display: none;
  color: #000;
}
@media (max-width: 992px) {
  .tab-section .tab-select {
    display: block;
  }
}
.tab-section .tab-cont {
  position: relative;
  width: 100%;
}
.tab-section .tab-cont .tab-item {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 0;
  pointer-events: none;
  z-index: -5;
  top: 0;
  transition: opacity 0.5s;
}
.tab-section .tab-cont .tab-item.active {
  position: relative;
  top: auto;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  z-index: 1;
}
@media (max-width: 576px) {
  .tab-section .tab-cont .tab-item.mobile-active {
    position: relative;
    top: auto;
    width: 100%;
    pointer-events: auto;
    opacity: 1;
    z-index: 1;
  }
}

/****************************************

SWIPER SECTION

include js: 

basic structure: 

****************************************/
.swiper-section {
  position: relative;
  width: 100%;
  height: auto;
}
.swiper-section .swiper-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 992px) {
  .swiper-section .swiper-nav {
    flex-direction: column;
  }
}
@media (max-width: 992px) {
  .swiper-section .swiper-nav {
    flex-direction: column;
  }
}
.swiper-section .swiper-nav .swiper-link {
  width: 100%;
  flex: 1 1 auto;
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.swiper-section .swiper-nav .swiper-link.active {
  text-decoration: underline;
}
.swiper-section .swiper-cont {
  overflow: hidden;
  position: relative;
}
.swiper-section .swiper-button-prev {
  background: none;
  width: 50px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  left: 5px;
}
.swiper-section .swiper-button-prev:after {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  top: 13px;
  left: 13px;
  transform: rotate(-135deg);
}
.swiper-section .swiper-button-next {
  background: none;
  width: 50px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  right: 5px;
}
.swiper-section .swiper-button-next:after {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  position: absolute;
  top: 13px;
  right: 13px;
  transform: rotate(45deg);
}
.swiper-section.light .swiper-button-next:after,
.swiper-section.light .swiper-button-prev:after {
  border-color: #fff;
}
.swiper-section .swiper-button-next.swiper-button-disabled,
.swiper-section .swiper-button-prev.swiper-button-disabled {
  opacity: 0.5;
}
.swiper-section .swiper-pagination {
  width: 100%;
  position: relative !important;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 45px;
}
.swiper-section .swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px;
  width: 17px;
  height: 17px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0);
  border: 3px solid #fff;
  transition: all 0.5s;
}
.swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
  border: 3px solid #fff;
  opacity: 1;
  width: 17px;
  height: 17px;
}
.swiper-section.inset {
  padding: 0 50px;
}

/****************************************

SITE SPECIFIC

****************************************/
.swiper-section .swiper-button-prev,
.swiper-section .swiper-button-next {
  background-image: url("../images/swiper-arrow.png");
  background-size: 100% auto;
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0;
  left: 20px;
  top: calc(100% - 130px);
  transition: transform 0.5s;
}
@media (max-width: 992px) {
  .swiper-section .swiper-button-prev,
  .swiper-section .swiper-button-next {
    top: calc(100% - 170px);
    left: 10px;
  }
}
.swiper-section .swiper-button-prev:hover,
.swiper-section .swiper-button-next:hover {
  transform: scale(1.2);
}
.swiper-section .swiper-button-prev:after,
.swiper-section .swiper-button-next:after {
  display: none;
}
.swiper-section .swiper-button-next {
  transform: rotate(180deg);
  right: 20px;
  left: auto;
}
@media (max-width: 992px) {
  .swiper-section .swiper-button-next {
    right: 10px;
  }
}
.swiper-section .swiper-button-next:hover {
  transform: rotate(180deg) scale(1.2);
}
.swiper-section.inset .swiper-button-prev {
  background: none;
  width: 50px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  left: 5px;
}
.swiper-section.inset .swiper-button-prev:after {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 1px solid #cda96e !important;
  border-right: 1px solid #cda96e !important;
  position: absolute;
  top: 13px;
  left: 13px;
  transform: rotate(-135deg);
  display: block !important;
}
.swiper-section.inset .swiper-button-next {
  background: none;
  width: 50px;
  height: 50px;
  margin: 0;
  top: calc((100% - 50px) / 2);
  right: 5px;
}
.swiper-section.inset .swiper-button-next:after {
  content: "";
  width: 20px;
  height: 20px;
  border-top: 1px solid #cda96e !important;
  border-right: 1px solid #cda96e !important;
  position: absolute;
  top: 13px;
  right: 13px;
  transform: rotate(-135deg) !important;
  display: block !important;
}

.map-section .column-layout .col:nth-of-type(1) {
  width: 300px;
}
.map-section .column-layout .col:nth-of-type(2) {
  width: calc(100% - 300px);
}
.map-section [data-map] {
  width: 100%;
  height: 100%;
  height: 500px;
}
.map-section [data-categories] {
  height: 200px;
  border: 1px solid black;
  width: 100%;
}
.map-section [data-categories] p {
  margin: 0;
}
.map-section [data-categories] .category {
  cursor: pointer;
}
.map-section [data-categories] .category p {
  margin: 0;
  line-height: 1.3;
}
.map-section [data-categories] .category.active p {
  font-weight: bold;
}
.map-section [data-pois] {
  width: 100%;
  height: 300px;
  overflow: scroll;
  position: relative;
  scroll-behavior: smooth;
  border: 1px solid black;
}
.map-section [data-pois] .poi {
  display: none;
}
.map-section [data-pois] .poi.active {
  display: block;
}
.map-section [data-key] .card {
  padding: 10px;
}
.map-section [data-key] .card p.title4 {
  margin: 0;
}
.map-section [data-key] .card .item {
  cursor: pointer;
}
.map-section [data-key] .card .item p {
  margin-bottom: 0;
}
.map-section [data-key] .card .toggle {
  cursor: pointer;
}
.map-section [data-key] .card .toggle .show {
  display: block;
}
.map-section [data-key] .card .toggle .hide {
  display: none;
}
.map-section [data-key] .card.active .toggle .show {
  display: none;
}
.map-section [data-key] .card.active .toggle .hide {
  display: block;
}
.map-section .info-window {
  width: 300px;
  padding: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.map-section .info-window p {
  margin: 0 0 5px 0;
  line-height: 1.2;
  text-align: center;
}
.map-section .info-window .btn {
  margin: 10px auto;
}
.map-section .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.map-section .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.map-section .gm-style [aria-label=Close] {
  width: 20px !important;
  height: 20px !important;
  top: 0 !important;
  right: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid black !important;
}
.map-section .gm-style [aria-label=Close] span {
  margin: 0 !important;
}

.blog-entry {
  display: flex;
  align-items: flex-start;
  padding: 8rem 0;
}
@media (max-width: 992px) {
  .blog-entry {
    flex-direction: column;
    padding: 4rem 0;
  }
}
.blog-entry:not(:last-of-type) {
  border-bottom: 1px solid #cda96e;
}
.blog-entry .thumbnail {
  width: 35%;
}
@media (max-width: 992px) {
  .blog-entry .thumbnail {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.blog-entry .thumbnail img {
  width: 100%;
  height: auto;
}
.blog-entry .inner {
  width: 65%;
  padding-left: 4rem;
}
@media (max-width: 992px) {
  .blog-entry .inner {
    width: 100%;
    padding-left: 0;
  }
}
.blog-entry .inner p.title3 {
  margin-bottom: 5px;
}
.blog-entry .inner a:not(.underline-link) {
  text-decoration: none;
}

.blog-posts {
  padding: 0;
}
.blog-posts .cont--lg {
  background: #f3f3f3;
  padding: 0 8rem;
}
@media (max-width: 992px) {
  .blog-posts .cont--lg {
    padding: 0 2rem;
  }
}

.single .first-img {
  margin-bottom: 2rem;
}

.blog .navigation {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.blog .navigation a,
.blog .navigation .current {
  color: #fff;
  background: #cda96e;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 1.6rem;
}
.blog-hero {
  flex-direction: column;
  padding: 0;
  margin-bottom: 4rem;
}
@media (max-width: 992px) {
  .blog-hero {
    margin-bottom: 2rem;
  }
}
.blog-hero .img {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: bottom center;
  margin-bottom: 4rem;
}
@media (max-width: 992px) {
  .blog-hero .img {
    height: 200px;
  }
}
.blog-hero .cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.single .blog-content {
  padding: 0;
}
.single .blog-content .cont--lg {
  background: #f3f3f3;
  padding: 8rem;
}
@media (max-width: 992px) {
  .single .blog-content .cont--lg {
    padding: 2rem;
  }
}
.single .blog-content .cont--lg p {
  width: 100%;
}
.single .blog-content .cont--lg img {
  width: 100%;
  height: auto;
}
.single .column-layout .col--two-thirds {
  width: 100%;
  padding-right: 4rem;
}
@media (max-width: 992px) {
  .single .column-layout .col--two-thirds {
    padding-right: 0;
  }
}
.single .column-layout .col.col--third {
  justify-content: flex-start;
  width: 300px;
  flex: none;
}
@media (max-width: 992px) {
  .single .column-layout .col.col--third {
    width: 100%;
    margin: 4rem 0;
  }
}
.single .column-layout .col.col--third p {
  margin-bottom: 1rem;
}
.single .column-layout .col.col--third a {
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 1px;
  line-height: 2;
  color: #000;
  margin-bottom: 2rem;
  color: #cda96e;
  width: 100%;
  display: block;
  margin-bottom: 1rem;
}
.single .column-layout .col.col--third a sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
.single .column-layout .col.col--third a.disclaimer {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.single .column-layout .col.col--third a.eyebrow {
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1;
}
.single .column-layout .col.col--third a.solo-eyebrow {
  text-transform: uppercase;
  color: #000;
}
.single .column-layout .col.col--third a a {
  color: #000;
  text-decoration: underline;
}

.page-template-home-template .hero-section {
  height: 100vh;
  max-height: 1080px;
  padding-top: 110px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 992px) {
  .page-template-home-template .hero-section {
    height: 65vh;
  }
}
.page-template-home-template .hero-section .img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
}
.page-template-home-template .hero-section .scroll {
  width: 100%;
  color: #fff;
  position: relative;
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.page-template-home-template .hero-section .scroll p {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 0;
}
.page-template-home-template .hero-section .scroll:after {
  content: "";
  width: 15px;
  height: 15px;
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  display: block;
  transform: rotate(-135deg);
  margin-top: -5px;
}
.page-template-home-template .content-sections {
  background: #f3f3f3;
  padding-bottom: 0;
}
.page-template-home-template .content-sections p.title2 {
  margin-bottom: 8rem;
  font-family: "articulat-cf", sans-serif;
  text-align: center;
  letter-spacing: 1.5px;
}
@media (max-width: 992px) {
  .page-template-home-template .content-sections p.title2 {
    margin-bottom: 4rem;
  }
}
.page-template-home-template .content-sections .section {
  background: #fff;
}
.page-template-home-template .content-sections .section:not(:last-of-type) {
  margin-bottom: 4rem;
}
.page-template-home-template .content-sections .section .column-layout .col:nth-of-type(1) {
  width: 60%;
}
@media (max-width: 992px) {
  .page-template-home-template .content-sections .section .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.page-template-home-template .content-sections .section .column-layout .col:nth-of-type(1) img {
  width: 100%;
  height: auto;
}
.page-template-home-template .content-sections .section .column-layout .col:nth-of-type(2) {
  width: 40%;
  padding: 4rem;
  text-align: center;
}
@media (max-width: 992px) {
  .page-template-home-template .content-sections .section .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding: 2rem;
  }
}
.page-template-home-template .content-sections .section .column-layout .col:nth-of-type(2) {
  position: relative;
  align-items: center;
  justify-content: center;
}
.page-template-home-template .content-sections .section:nth-of-type(even) .column-layout {
  flex-direction: row-reverse;
}
@media (max-width: 992px) {
  .page-template-home-template .content-sections .section:nth-of-type(even) .column-layout {
    flex-direction: column;
  }
}
.page-template-home-template .about-section {
  background: #f3f3f3;
}
.page-template-home-template .about-section h2 {
  text-align: center;
  font-family: "articulat-cf", sans-serif;
  letter-spacing: 1.5px;
}
.page-template-home-template .about-section p a {
  color: #cda96e;
  text-decoration: none;
}

.page-template-about-template .hero-section {
  background: #f3f3f3;
}
.page-template-about-template .experience-section {
  background: #f3f3f3;
  padding-top: 0;
}
.page-template-about-template .experience-section .column-layout {
  background: #fff;
}
.page-template-about-template .experience-section .column-layout .col:nth-of-type(2) {
  width: 45%;
  padding: 8rem 4rem;
}
@media (max-width: 992px) {
  .page-template-about-template .experience-section .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding: 2rem;
  }
}
.page-template-about-template .experience-section .column-layout .col:nth-of-type(2) h2 {
  font-family: "articulat-cf", sans-serif;
}
.page-template-about-template .experience-section .column-layout .col:nth-of-type(1) {
  width: 55%;
  position: relative;
  overflow: hidden;
}
@media (max-width: 992px) {
  .page-template-about-template .experience-section .column-layout .col:nth-of-type(1) {
    width: 100%;
    height: 50vh;
  }
}
.page-template-about-template .experience-section .column-layout .col:nth-of-type(1) img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-template-about-template .team-section .card {
  padding: 0;
}
.page-template-about-template .team-section .card[href] {
  cursor: pointer;
}
.page-template-about-template .team-section .card img {
  width: 100%;
  height: auto;
}
.page-template-about-template .team-section .card .btm {
  width: 100%;
  border: 1px solid #000;
  padding: 5px 10px 10px 5px;
  text-align: center;
  color: #cda96e;
  margin: 0;
  margin-top: 1rem;
  background-color: #fff;
  transition: background-color 0.5s;
}
.page-template-about-template .team-section .card .btm p {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}
.page-template-about-template .bio {
  max-width: 800px;
}
.page-template-about-template .bio img {
  width: 100%;
  height: auto;
}
@media (max-width: 992px) {
  .page-template-about-template .bio img {
    margin-bottom: 4rem;
  }
}

.page-template-cabinet-template section {
  background: #f3f3f3;
}
.page-template-cabinet-template .content-section {
  padding: 0;
}
.page-template-cabinet-template .content-section .column-layout {
  background: #fff;
  padding: 8rem;
}
@media (max-width: 992px) {
  .page-template-cabinet-template .content-section .column-layout {
    padding: 4rem;
  }
}
.page-template-cabinet-template .content-section .column-layout .col:nth-of-type(1) {
  width: calc(100% - 380px);
  padding-right: 4rem;
}
@media (max-width: 992px) {
  .page-template-cabinet-template .content-section .column-layout .col:nth-of-type(1) {
    width: 100%;
    padding-right: 0;
  }
}
.page-template-cabinet-template .content-section .column-layout .col:nth-of-type(2) {
  width: 380px;
  flex: none;
}
@media (max-width: 992px) {
  .page-template-cabinet-template .content-section .column-layout .col:nth-of-type(2) {
    width: 100%;
  }
}
.page-template-cabinet-template .content-section .column-layout .col:nth-of-type(2) img {
  width: 100%;
  height: auto;
}

.page-template-gallery-template .hero-section,
.page-template-gallery-template .gallery-section {
  background: #f3f3f3;
}
.page-template-gallery-template .gallery-section {
  padding-top: 0;
}
.page-template-gallery-template .gallery-section .cont--lg {
  background: #fff;
  padding: 8rem;
}
@media (max-width: 992px) {
  .page-template-gallery-template .gallery-section .cont--lg {
    padding: 2rem 2rem 8rem;
  }
}
@media (max-width: 992px) {
  .page-template-gallery-template .swiper-slide .column-layout .col:nth-of-type(1) {
    margin-bottom: 2rem;
  }
}
.page-template-gallery-template .swiper-slide .column-layout .col:nth-of-type(1) a {
  width: 100%;
}
.page-template-gallery-template .swiper-slide .column-layout .col:nth-of-type(2) h2 {
  margin-bottom: 10px;
}
.page-template-gallery-template .swiper-slide .column-layout .col:nth-of-type(2) .title3 {
  color: #cda96e;
}
.page-template-gallery-template .swiper-slide .column-layout .col:nth-of-type(2) .btn {
  margin-top: 2rem;
  align-self: flex-end;
}
@media (max-width: 992px) {
  .page-template-gallery-template .swiper-slide .column-layout .col:nth-of-type(2) .btn {
    align-self: flex-start;
  }
}
.page-template-gallery-template .swiper-section .swiper-pagination {
  width: 100%;
  position: relative !important;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -4rem;
}
.page-template-gallery-template .swiper-section .swiper-pagination .swiper-pagination-bullet {
  margin: 0 5px;
  width: 17px;
  height: 17px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0);
  border: 3px solid #cda96e;
  transition: all 0.5s;
}
.page-template-gallery-template .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #cda96e;
  border: 3px solid #cda96e;
  opacity: 1;
  width: 17px;
  height: 17px;
}

.page-template-showroom-template section {
  background: #f3f3f3;
}
.page-template-showroom-template .process-section .card {
  background: #cda96e;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.page-template-showroom-template .process-section .card .number {
  font-family: "le-monde-livre-std", sans-serif;
  font-size: 150px;
  position: absolute;
  bottom: 0;
  left: -5px;
  margin: 0;
  line-height: 0.7;
  color: #cda96e;
  transition: opacity 0.5s;
}
.page-template-showroom-template .process-section .card .img-cont {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
  transition: opacity 0.5s;
}
.page-template-showroom-template .process-section .card .img-cont img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  object-fit: cover;
}
.page-template-showroom-template .process-section .card .hover {
  padding: 4rem 2rem;
}
.page-template-showroom-template .process-section .card .hover p {
  color: #fff;
}
.page-template-showroom-template .process-section .card:hover .number {
  opacity: 0;
}
.page-template-showroom-template .process-section .card:hover .img-cont {
  opacity: 0;
}
.page-template-showroom-template .testimonial-section .open-quote {
  color: #cda96e;
  font-size: 120px;
  position: absolute;
  line-height: 0;
}
@media (max-width: 992px) {
  .page-template-showroom-template .testimonial-section .open-quote {
    position: relative;
    margin: 0 auto;
    display: block;
    width: 100%;
    text-align: center;
  }
}
.page-template-showroom-template .testimonial-section .quote {
  padding-left: 50px;
}
@media (max-width: 992px) {
  .page-template-showroom-template .testimonial-section .quote {
    padding-left: 0;
  }
}
.page-template-showroom-template .testimonial-section p {
  font-family: "le-monde-livre-std", sans-serif;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 1;
  color: #000;
  margin-bottom: 2rem;
  font-family: "articulat-cf", sans-serif;
  letter-spacing: 1.5px;
  line-height: 1.8;
}
@media (max-width: 992px) {
  .page-template-showroom-template .testimonial-section p {
    font-size: 2.2rem;
  }
}
@media (max-width: 992px) {
  .page-template-showroom-template .testimonial-section p {
    text-align: center;
  }
}
.page-template-showroom-template .testimonial-section .author {
  text-align: right;
}
@media (max-width: 992px) {
  .page-template-showroom-template .testimonial-section .author {
    text-align: center;
  }
}
.page-template-showroom-template .map-section {
  background: #fff;
}
.page-template-showroom-template .map-section [data-map] {
  width: 100%;
  height: 100%;
  height: 500px;
  margin-bottom: 4rem;
}
.page-template-showroom-template .map-section .info-window {
  width: 300px;
  padding: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-template-showroom-template .map-section .info-window p {
  margin: 0 0 5px 0;
  line-height: 1.2;
  text-align: center;
}
.page-template-showroom-template .map-section .info-window .btn {
  margin: 10px auto;
}
.page-template-showroom-template .map-section .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.page-template-showroom-template .map-section .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.page-template-showroom-template .map-section .gm-style [aria-label=Close] {
  width: 20px !important;
  height: 20px !important;
  top: 0 !important;
  right: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid black !important;
}
.page-template-showroom-template .map-section .gm-style [aria-label=Close] span {
  margin: 0 !important;
}

.page-template-builders-home-template header {
  background: none;
}
.page-template-builders-home-template header .logo .color-logo {
  display: none;
}
.page-template-builders-home-template header .logo .white-logo {
  display: block;
}
.page-template-builders-home-template header .inner {
  padding: 4rem 0;
}
@media (max-width: 992px) {
  .page-template-builders-home-template header .highlight-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2rem;
  }
}
.page-template-builders-home-template header .highlight-menu a {
  color: #fff;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
  letter-spacing: 2.5px;
}
.page-template-builders-home-template header .highlight-menu .sub-menu {
  display: none;
}
.page-template-builders-home-template header .highlight-menu .menu-highlight-menu-container {
  width: 100%;
}
@media (max-width: 992px) {
  .page-template-builders-home-template header .highlight-menu ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 500px;
  }
}
@media (max-width: 992px) {
  .page-template-builders-home-template header .highlight-menu ul li {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-template-builders-home-template header .highlight-menu ul li a {
    width: 80%;
    text-align: center;
  }
}
.page-template-builders-home-template header .highlight-menu ul li:not(:last-of-type) {
  margin-right: 10px;
}
@media (max-width: 992px) {
  .page-template-builders-home-template header .highlight-menu ul li:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.page-template-builders-home-template .header-spacing {
  display: none;
}
.page-template-builders-home-template .hero-section {
  height: 100vh;
}
.page-template-builders-home-template .hero-section .img {
  height: 100%;
}
.page-template-builders-home-template footer {
  display: none;
}

.page-template-custom-homes-template .builder-hero-section .jump-nav,
.page-template-communities-template .builder-hero-section .jump-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .builder-hero-section .jump-nav,
  .page-template-communities-template .builder-hero-section .jump-nav {
    display: none;
  }
}
.page-template-custom-homes-template .builder-hero-section .jump-nav .submenu-toggle,
.page-template-communities-template .builder-hero-section .jump-nav .submenu-toggle {
  display: none;
  margin: 0;
  text-align: right;
  text-transform: uppercase;
  color: #fff;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .builder-hero-section .jump-nav .submenu-toggle,
  .page-template-communities-template .builder-hero-section .jump-nav .submenu-toggle {
    display: block;
  }
}
.page-template-custom-homes-template .builder-hero-section .jump-nav .cont,
.page-template-communities-template .builder-hero-section .jump-nav .cont {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.page-template-custom-homes-template .builder-hero-section .jump-nav ul,
.page-template-communities-template .builder-hero-section .jump-nav ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .builder-hero-section .jump-nav ul,
  .page-template-communities-template .builder-hero-section .jump-nav ul {
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
  }
}
.page-template-custom-homes-template .builder-hero-section .jump-nav ul li a,
.page-template-communities-template .builder-hero-section .jump-nav ul li a {
  color: #fff;
  text-decoration: none;
  letter-spacing: 1.5px;
  padding: 0 15px;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .builder-hero-section .jump-nav ul li a,
  .page-template-communities-template .builder-hero-section .jump-nav ul li a {
    padding: 0;
  }
}
.page-template-custom-homes-template .builder-hero-section .jump-nav.active ul,
.page-template-communities-template .builder-hero-section .jump-nav.active ul {
  max-height: 500px;
}
.page-template-custom-homes-template .builder-hero-section h1,
.page-template-communities-template .builder-hero-section h1 {
  max-width: 700px;
  margin: 0 auto 4rem;
}
.page-template-custom-homes-template .steps-section,
.page-template-communities-template .steps-section {
  background: #f3f3f3;
  padding-top: 0;
}
.page-template-custom-homes-template .steps-section h2,
.page-template-communities-template .steps-section h2 {
  margin: 4rem 0;
  font-family: "articulat-cf", sans-serif;
  color: #cda96e;
  letter-spacing: 1.5px;
}
.page-template-custom-homes-template .steps-section .title3,
.page-template-communities-template .steps-section .title3 {
  margin: 0 0 4rem;
  font-family: "articulat-cf", sans-serif;
  color: #cda96e;
  letter-spacing: 1.5px;
}
.page-template-custom-homes-template .steps-section .swiper-section,
.page-template-communities-template .steps-section .swiper-section {
  background: #fff;
  padding: 4rem 0;
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination {
  width: 100%;
  position: relative !important;
  padding: 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: auto;
  top: auto;
  z-index: 9;
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet {
  margin: 0 10px;
  width: 35px;
  height: 35px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #cda96e;
  transition: all 0.5s;
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:after {
  content: "0";
  display: block;
  color: #cda96e;
  font-size: 24px;
  transform: translate(0, -2px);
  transition: all 0.5s;
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(1):after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(1):after {
  content: "1";
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(2):after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(2):after {
  content: "2";
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(3):after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(3):after {
  content: "3";
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(4):after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(4):after {
  content: "4";
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(5):after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(5):after {
  content: "5";
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(6):after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(6):after {
  content: "6";
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #cda96e;
  color: #fff;
}
.page-template-custom-homes-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after,
.page-template-communities-template .steps-section .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  color: #fff;
}
.page-template-custom-homes-template .steps-section .swiper-section .column-layout h3,
.page-template-communities-template .steps-section .swiper-section .column-layout h3 {
  text-align: left;
}
.page-template-custom-homes-template .steps-section .swiper-section .column-layout .col:nth-of-type(1),
.page-template-communities-template .steps-section .swiper-section .column-layout .col:nth-of-type(1) {
  width: 55%;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .steps-section .swiper-section .column-layout .col:nth-of-type(1),
  .page-template-communities-template .steps-section .swiper-section .column-layout .col:nth-of-type(1) {
    width: 100%;
  }
}
.page-template-custom-homes-template .steps-section .swiper-section .column-layout .col:nth-of-type(2),
.page-template-communities-template .steps-section .swiper-section .column-layout .col:nth-of-type(2) {
  width: 45%;
  padding: 0 4rem;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .steps-section .swiper-section .column-layout .col:nth-of-type(2),
  .page-template-communities-template .steps-section .swiper-section .column-layout .col:nth-of-type(2) {
    width: 100%;
    padding: 4rem 2rem 0;
  }
}
.page-template-custom-homes-template .work-section .tab-nav,
.page-template-communities-template .work-section .tab-nav {
  justify-content: center;
  margin-bottom: 4rem;
}
.page-template-custom-homes-template .work-section .tab-nav .tab-link,
.page-template-communities-template .work-section .tab-nav .tab-link {
  width: auto;
  flex: none;
  padding: 0;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .work-section .tab-nav .tab-link,
  .page-template-communities-template .work-section .tab-nav .tab-link {
    display: none;
  }
}
.page-template-custom-homes-template .work-section .tab-nav .tab-link:not(:last-of-type),
.page-template-communities-template .work-section .tab-nav .tab-link:not(:last-of-type) {
  margin-right: 2rem;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .work-section .tab-nav .tab-link:not(:last-of-type),
  .page-template-communities-template .work-section .tab-nav .tab-link:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.page-template-custom-homes-template .work-section .tab-nav .tab-link p,
.page-template-communities-template .work-section .tab-nav .tab-link p {
  margin: 0;
}
.page-template-custom-homes-template .work-section .btn.center,
.page-template-communities-template .work-section .btn.center {
  margin: 4rem auto;
}
.page-template-custom-homes-template .bottom-section,
.page-template-communities-template .bottom-section {
  background: #f3f3f3;
}
.page-template-custom-homes-template .site-plan-section,
.page-template-communities-template .site-plan-section {
  background: #f3f3f3;
}
.page-template-custom-homes-template .site-plan-section img,
.page-template-communities-template .site-plan-section img {
  max-width: 700px;
  width: 100%;
  height: auto;
  margin: 4rem auto 0;
  display: block;
}
.page-template-custom-homes-template .location-section [data-map],
.page-template-communities-template .location-section [data-map] {
  width: 100%;
  min-height: 400px;
  height: 70vh;
  margin: 4rem auto 0;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .location-section [data-map],
  .page-template-communities-template .location-section [data-map] {
    height: 50vh;
  }
}
.page-template-custom-homes-template .location-section .info-window,
.page-template-communities-template .location-section .info-window {
  width: 300px;
  padding: 10px;
  border: 2px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-template-custom-homes-template .location-section .info-window p,
.page-template-communities-template .location-section .info-window p {
  margin: 0 0 5px 0;
  line-height: 1.2;
  text-align: center;
}
.page-template-custom-homes-template .location-section .info-window .btn,
.page-template-communities-template .location-section .info-window .btn {
  margin: 10px auto;
}
.page-template-custom-homes-template .location-section .gm-style .gm-style-iw-d,
.page-template-communities-template .location-section .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.page-template-custom-homes-template .location-section .gm-style .gm-style-iw-c,
.page-template-communities-template .location-section .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.page-template-custom-homes-template .location-section .gm-style [aria-label=Close],
.page-template-communities-template .location-section .gm-style [aria-label=Close] {
  width: 20px !important;
  height: 20px !important;
  top: 0 !important;
  right: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid black !important;
}
.page-template-custom-homes-template .location-section .gm-style [aria-label=Close] span,
.page-template-communities-template .location-section .gm-style [aria-label=Close] span {
  margin: 0 !important;
}
.page-template-custom-homes-template .map-section .key,
.page-template-communities-template .map-section .key {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.page-template-custom-homes-template .map-section .key .key-item,
.page-template-communities-template .map-section .key .key-item {
  cursor: pointer;
}
.page-template-custom-homes-template .map-section .key .key-item p,
.page-template-communities-template .map-section .key .key-item p {
  transition: color 0.5s;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .map-section .key .key-item p,
  .page-template-communities-template .map-section .key .key-item p {
    font-size: 1.4rem;
  }
}
.page-template-custom-homes-template .map-section .key .key-item.active p, .page-template-custom-homes-template .map-section .key .key-item:hover p,
.page-template-communities-template .map-section .key .key-item.active p,
.page-template-communities-template .map-section .key .key-item:hover p {
  color: #cda96e;
  text-decoration: underline;
}
.page-template-custom-homes-template .map-section .key .key-item:not(:last-of-type),
.page-template-communities-template .map-section .key .key-item:not(:last-of-type) {
  margin-right: 2rem;
}
.page-template-custom-homes-template .map-section .the-map,
.page-template-communities-template .map-section .the-map {
  margin-bottom: 2rem;
}
.page-template-custom-homes-template .map-section .iw,
.page-template-communities-template .map-section .iw {
  padding: 10px 40px;
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .map-section .iw,
  .page-template-communities-template .map-section .iw {
    padding: 10px 30px;
  }
}
@media (max-width: 992px) {
  .page-template-custom-homes-template .map-section .iw p.title3,
  .page-template-communities-template .map-section .iw p.title3 {
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 15px;
  }
}
.page-template-custom-homes-template .map-section .iw .btn,
.page-template-communities-template .map-section .iw .btn {
  text-transform: uppercase;
}
.page-template-custom-homes-template .map-section .gm-style-iw-chr,
.page-template-communities-template .map-section .gm-style-iw-chr {
  margin-bottom: -25px;
}
.page-template-custom-homes-template .map-section .gm-style .gm-style-iw-d,
.page-template-communities-template .map-section .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.page-template-custom-homes-template .map-section .gm-style .gm-style-iw-c,
.page-template-communities-template .map-section .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.page-template-custom-homes-template .map-section .gm-style [aria-label=Close],
.page-template-communities-template .map-section .gm-style [aria-label=Close] {
  width: 26px !important;
  height: 26px !important;
  top: 0 !important;
  right: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid black !important;
}
.page-template-custom-homes-template .map-section .gm-style [aria-label=Close] span,
.page-template-communities-template .map-section .gm-style [aria-label=Close] span {
  margin: 0 !important;
}

.page-template-communities-template .builder-hero-section h1 {
  margin: 0 0 1rem 0;
}
.page-template-communities-template .steps-section h2 {
  margin-bottom: 0;
}

#schedule-an-appointment {
  max-width: 800px;
  width: 100%;
}
#schedule-an-appointment .nf-form-cont {
  display: flex;
  flex-direction: column;
}
#schedule-an-appointment .nf-form-cont .nf-form-title h3 {
  font-family: "articulat-cf", sans-serif;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 1.5px;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap {
  width: 100%;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap .nf-before-form-content {
  display: none;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap .nf-form-content {
  padding: 0;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-fields-wrap {
  display: flex;
  flex-wrap: wrap;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field {
  width: 100%;
  display: flex;
}
@media (max-width: 992px) {
  #schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field {
    width: 100%;
  }
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field:nth-of-type(8) {
  display: none;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field .nf-field-container {
  width: 100%;
  margin-bottom: 1rem;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field input,
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field textarea {
  border: none;
  background: #f3f3f3;
  letter-spacing: 1.5px;
  width: 100%;
  font-family: "articulat-cf", sans-serif;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field textarea {
  height: 100px;
  font-family: "articulat-cf", sans-serif;
}
#schedule-an-appointment .nf-form-cont .nf-form-wrap nf-field .submit-container input {
  background: #fff;
  color: #cda96e;
  text-align: right;
  height: auto;
  padding: 0;
}

.page-template-contact-template .hero-section,
.page-template-contact-template .contact-section {
  background: #fff;
}
.page-template-contact-template .hero-section .cont {
  padding-bottom: 2rem;
}
.page-template-contact-template .nf-form-cont h3,
.page-template-contact-template .nf-form-cont .nf-form-fields-required {
  display: none;
}
.page-template-contact-template .contact-section {
  padding-top: 0;
}
.page-template-contact-template footer .contact-btn {
  display: none;
}

.page-template-testimonials-template .testimonials-section {
  background: #f3f3f3;
  padding-top: 0;
}
.page-template-testimonials-template .testimonials-section h2 {
  margin: 4rem 0;
  font-family: "articulat-cf", sans-serif;
  color: #cda96e;
  letter-spacing: 1.5px;
}
.page-template-testimonials-template .testimonials-section .swiper-section {
  background: #fff;
  padding: 4rem 0;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination {
  width: 100%;
  position: relative !important;
  padding: 0 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: auto;
  top: auto;
  z-index: 9;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet {
  margin: 0 10px;
  width: 35px;
  height: 35px;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #cda96e;
  transition: all 0.5s;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:after {
  content: "0";
  display: block;
  color: #cda96e;
  font-size: 24px;
  transform: translate(0, -2px);
  transition: all 0.5s;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(1):after {
  content: "1";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(2):after {
  content: "2";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(3):after {
  content: "3";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(4):after {
  content: "4";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(5):after {
  content: "5";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(6):after {
  content: "6";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(7):after {
  content: "7";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(8):after {
  content: "8";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(9):after {
  content: "9";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(10):after {
  content: "10";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(11):after {
  content: "11";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(12):after {
  content: "12";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(13):after {
  content: "13";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(14):after {
  content: "14";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(15):after {
  content: "15";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(16):after {
  content: "16";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(17):after {
  content: "17";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(18):after {
  content: "18";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(19):after {
  content: "19";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(20):after {
  content: "20";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(21):after {
  content: "21";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(22):after {
  content: "22";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(23):after {
  content: "23";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(24):after {
  content: "24";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(25):after {
  content: "25";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(26):after {
  content: "26";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(27):after {
  content: "27";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(28):after {
  content: "28";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(29):after {
  content: "29";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(30):after {
  content: "30";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(31):after {
  content: "31";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(32):after {
  content: "32";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(33):after {
  content: "33";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(34):after {
  content: "34";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(35):after {
  content: "35";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(36):after {
  content: "36";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(37):after {
  content: "37";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(38):after {
  content: "38";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(39):after {
  content: "39";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(40):after {
  content: "40";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(41):after {
  content: "41";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(42):after {
  content: "42";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(43):after {
  content: "43";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(44):after {
  content: "44";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(45):after {
  content: "45";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(46):after {
  content: "46";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(47):after {
  content: "47";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(48):after {
  content: "48";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(49):after {
  content: "49";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet:nth-of-type(50):after {
  content: "50";
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #cda96e;
  color: #fff;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  color: #fff;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide {
  padding: 0 4rem;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .column-layout {
  align-items: flex-start;
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .column-layout .col:nth-of-type(1) {
  width: 50px;
  transform: translate(0, 10px);
}
@media (max-width: 992px) {
  .page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .column-layout .col:nth-of-type(1) {
    width: 100%;
    transform: translate(0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
  }
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .column-layout .col:nth-of-type(2) {
  width: calc(100% - 50px);
}
@media (max-width: 992px) {
  .page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .column-layout .col:nth-of-type(2) {
    width: 100%;
  }
}
.page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .author {
  text-align: right;
  width: 100%;
  color: #cda96e;
  font-style: italic;
  padding-right: 4rem;
  margin: 0;
}
@media (max-width: 992px) {
  .page-template-testimonials-template .testimonials-section .swiper-section .swiper-slide .author {
    padding-right: 0;
  }
}

.page-template-past-communities-template .communities-section {
  padding-top: 0;
}
.page-template-past-communities-template .communities-section .card-layout.halves .card {
  width: calc((100% - 8rem) / 2);
  margin-bottom: 8rem;
}
@media (max-width: 1200px) {
  .page-template-past-communities-template .communities-section .card-layout.halves .card {
    width: calc((100% - 8rem) / 2);
    margin-bottom: 8rem;
  }
}
@media (max-width: 992px) {
  .page-template-past-communities-template .communities-section .card-layout.halves .card {
    width: 100%;
  }
}
.page-template-past-communities-template .communities-section .card-layout.halves .card:not(:nth-of-type(even)) {
  margin-right: 8rem;
}
@media (max-width: 1200px) {
  .page-template-past-communities-template .communities-section .card-layout.halves .card:not(:nth-of-type(even)) {
    margin-right: 8rem;
  }
}
@media (max-width: 992px) {
  .page-template-past-communities-template .communities-section .card-layout.halves .card:not(:nth-of-type(even)) {
    margin-right: 0;
  }
}
.page-template-past-communities-template .communities-section .card-layout.halves .card:last-of-type {
  margin-right: 0;
}
.page-template-past-communities-template .communities-section .card .img-crop {
  margin-bottom: 2rem;
}
.page-template-past-communities-template .communities-section .card p.title4 {
  margin-bottom: 1rem;
}