.map-section {
	.column-layout {
		.col {
			&:nth-of-type(1) {
				width: 300px;
			}

			&:nth-of-type(2) {
				width: calc(100% - 300px);
			}
		}
	}

	[data-map] {
		width: 100%;
		height: 100%;
		height: 500px;
	}

	[data-categories] {
		height: 200px;
		border: 1px solid black;
		width: 100%;

		p {
			margin: 0;
		}

		.category {
			cursor: pointer;

			p {
				margin: 0;
				line-height: 1.3;
			}

			&.active {
				p {
					font-weight: bold;
				}
			}
		}
	}

	[data-pois] {
		width: 100%;
		height: 300px;
		overflow: scroll;
		position: relative;
		scroll-behavior: smooth;
		border: 1px solid black;

		.poi {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	[data-key] {
		.card {
			padding: 10px;

			p.title4 {
				margin: 0;
			}

			.item {
				cursor: pointer;

				p {
					margin-bottom: 0;
				}
			}

			.toggle {
				cursor: pointer;

				.show {
					display: block;
				}

				.hide {
					display: none;
				}
			}

			&.active {
				.toggle {
					.show {
						display: none;
					}

					.hide {
						display: block;
					}
				}
			}
		}
	}

	.info-window {
		width: 300px;
		//height: 100px;
		padding: 10px;
		border: 2px solid black;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		p {
			margin: 0 0 5px 0;
			line-height: 1.2;
			text-align: center;
		}

		.btn {
			margin: 10px auto;
		}
	}

	.gm-style {
		.gm-style-iw-d {
			overflow: auto !important;
		}

		.gm-style-iw-c {
			padding: 0;
			border-radius: 0;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
		}

		[aria-label="Close"] {
			width: 20px !important;
			height: 20px !important;
			top: 0 !important;
			right: 0 !important;
			display: flex !important;
			align-items: center;
			justify-content: center;
			border: 1px solid black !important;

			span {
				margin: 0 !important;
			}
		}
	}
}
