.page-template-gallery-template {
	.hero-section,
	.gallery-section {
		background: color(grey);
	}

	.gallery-section {
		padding-top: 0;

		.cont--lg {
			background: color(white);
			padding: $padding--lg;

			@media (max-width: bp(nav)) {
				padding: $padding--sm $padding--sm $padding--lg;
			}
		}
	}

	.swiper-slide {
		//padding: $padding--lg;

		.column-layout {
			.col {
				&:nth-of-type(1) {
					@media (max-width: bp(nav)) {
						margin-bottom: $padding--sm;
					}

					a {
						width: 100%;
					}
				}

				&:nth-of-type(2) {
					h2 {
						margin-bottom: 10px;
					}

					.title3 {
						color: color(accent);
					}

					.btn {
						margin-top: $padding--sm;

						align-self: flex-end;

						@media (max-width: bp(nav)) {
							align-self: flex-start;
						}
					}
				}
			}
		}
	}

	.swiper-section {
		.swiper-pagination {
			width: 100%;
			position: relative !important;
			padding: 5px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			bottom: #{$padding--lg * -0.5};

			.swiper-pagination-bullet {
				margin: 0 5px;
				width: 17px;
				height: 17px;
				opacity: 1;
				background-color: rgba(255, 255, 255, 0);
				border: 3px solid color(accent);
				transition: all 0.5s;

				&.swiper-pagination-bullet-active {
					background-color: color(accent);
					border: 3px solid color(accent);
					opacity: 1;
					width: 17px;
					height: 17px;
				}
			}
		}
	}
}
