/****************************************

BUTTONS

****************************************/

@mixin btnStyle {
	padding: 10px 25px;
	font-size: 1.6rem;
	line-height: 1;
	font-weight: 400;
	color: color(white);
	background-color: color(accent);
	text-decoration: none;
	text-align: center;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		&:after {
			transform: translate(5px, 0px);
		}
	}
}

.btn {
	@include btnStyle;

	&.center {
		margin: 0 auto;
		display: table;
		width: auto;
	}

	&.video {
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: "";
			width: 0;
			height: 0;
			display: block;
			margin-left: 7px;
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-left: 10px solid #fff;
		}
	}

	&.secondary {
		border: 1px solid color(accent, orange);
		color: color(white);
		background-color: color(accent, orange);

		&:hover {
			border: 1px solid color(accent);
			background-color: color(accent);
			color: color(white);
		}

		&.disabled {
			pointer-events: none;
			border: none;
		}

		&.apply {
			border-radius: 3px;
			padding: 5px 10px 7px 10px;
		}

		&.large {
			padding: 15px 25px;
		}
	}

	&.tertiary {
		color: color(black);
		background-color: color(accent);
	}
}

.underline-link {
	@include p-font-styles;
	text-decoration: underline;
	line-height: 1.5rem;
	color: color(accent);
}

.links {
	display: flex;
	align-items: center;

	*:not(:last-of-type) {
		margin-right: 30px;
	}
}

.video-btn {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	text-decoration: none;
	color: color(black);

	img {
		width: 30px;
		height: auto;
		margin-left: 5px;
		transition: transform $animationDuration;
	}

	&:hover {
		img {
			transform: translate(5px, 0);
		}
	}
}
