.page-template-builders-home-template {
	header {
		background: none;

		.logo {
			.color-logo {
				display: none;
			}

			.white-logo {
				display: block;
			}
		}

		.inner {
			padding: $padding--md 0;
		}

		.highlight-menu {
			@media (max-width: bp(nav)) {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: $padding--sm;
			}

			a {
				color: color(white);
				border: 1px solid color(white);
				background: rgba(0, 0, 0, 0.5);
				padding: 5px 10px;
				letter-spacing: 2.5px;
			}

			.sub-menu {
				display: none;
			}

			.menu-highlight-menu-container {
				width: 100%;
			}

			ul {
				@media (max-width: bp(nav)) {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					max-height: 500px;
				}

				li {
					@media (max-width: bp(nav)) {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						a {
							width: 80%;
							text-align: center;
						}
					}

					&:not(:last-of-type) {
						margin-right: 10px;

						@media (max-width: bp(nav)) {
							margin-right: 0;
							margin-bottom: $padding--sm;
						}
					}
				}
			}
		}
	}

	.header-spacing {
		display: none;
	}

	.hero-section {
		height: 100vh;

		.img {
			height: 100%;
		}
	}

	footer {
		display: none;
	}
}
