.page-template-contact-template {
	.hero-section,
	.contact-section {
		background: color(white);
	}

	.hero-section .cont {
		padding-bottom: $padding--sm;
	}

	.nf-form-cont {
		h3,
		.nf-form-fields-required {
			display: none;
		}
	}

	.contact-section {
		padding-top: 0;
	}

	footer {
		.contact-btn {
			display: none;
		}
	}
}

.page-template-testimonials-template {
	.testimonials-section {
		background: color(grey);
		padding-top: 0;

		h2 {
			margin: $padding--md 0;
			font-family: $bodyFont;
			color: color(accent);
			letter-spacing: 1.5px;
		}

		.swiper-section {
			background: color(white);
			padding: $padding--md 0;

			.swiper-pagination {
				width: 100%;
				position: relative !important;
				padding: 0 0 $padding--sm;
				display: flex;
				align-items: center;
				justify-content: center;
				bottom: auto;
				top: auto;
				z-index: 9;

				.swiper-pagination-bullet {
					margin: 0 10px;
					width: 35px;
					height: 35px;
					opacity: 1;
					background-color: rgba(255, 255, 255, 0);
					border: 1px solid color(accent);
					transition: all 0.5s;

					&:after {
						content: "0";
						display: block;
						color: color(accent);
						font-size: 24px;
						transform: translate(0, -2px);
						transition: all 0.5s;
					}

					@for $i from 1 through 50 {
						&:nth-of-type(#{$i}):after {
							content: "#{$i}";
						}
					}

					&.swiper-pagination-bullet-active {
						background: color(accent);
						color: color(white);

						&:after {
							color: color(white);
						}
					}
				}
			}

			.swiper-slide {
				padding: 0 $padding--md;

				.column-layout {
					align-items: flex-start;

					.col {
						&:nth-of-type(1) {
							width: 50px;
							transform: translate(0, 10px);

							@media (max-width: bp(nav)) {
								width: 100%;
								transform: translate(0, 0);
								display: flex;
								justify-content: center;
								align-items: center;
								margin: 1rem 0;
							}
						}

						&:nth-of-type(2) {
							width: calc(100% - 50px);

							@media (max-width: bp(nav)) {
								width: 100%;
							}
						}
					}
				}

				.author {
					text-align: right;
					width: 100%;
					color: color(accent);
					font-style: italic;
					padding-right: $padding--md;
					margin: 0;

					@media (max-width: bp(nav)) {
						padding-right: 0;
					}
				}
			}
		}
	}
}

.page-template-past-communities-template {
	.communities-section {
		padding-top: 0;

		.card-layout.halves {
			.card {
				width: calc((100% - #{$padding--lg}) / 2);
				margin-bottom: $padding--lg;

				@media (max-width: bp(xl)) {
					width: calc((100% - #{$padding--lg}) / 2);
					margin-bottom: $padding--lg;
				}

				@media (max-width: $mobile) {
					width: 100%;
				}

				&:not(:nth-of-type(even)) {
					margin-right: $padding--lg;

					@media (max-width: bp(xl)) {
						margin-right: $padding--lg;
					}

					@media (max-width: $mobile) {
						margin-right: 0;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		.card {
			.img-crop {
				margin-bottom: $padding--sm;
			}

			p.title4 {
				margin-bottom: $gap;
			}
		}
	}
}
