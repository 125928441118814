.page-template-about-template {
	.hero-section {
		background: color(grey);
	}

	.experience-section {
		background: color(grey);
		padding-top: 0;

		.column-layout {
			background: color(white);

			.col {
				&:nth-of-type(2) {
					width: 45%;
					padding: $padding--lg $padding--md;

					@media (max-width: bp(nav)) {
						width: 100%;
						padding: $padding--sm;
					}

					h2 {
						font-family: $bodyFont;
					}
				}

				&:nth-of-type(1) {
					width: 55%;
					position: relative;
					overflow: hidden;

					@media (max-width: bp(nav)) {
						width: 100%;
						height: 50vh;
					}

					img {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}

	.team-section {
		//padding-top: 0;

		.card {
			padding: 0;

			&[href] {
				cursor: pointer;
			}

			img {
				width: 100%;
				height: auto;
			}

			.btm {
				width: 100%;
				border: 1px solid color(black);
				padding: 5px 10px 10px 5px;
				text-align: center;
				color: color(accent);
				margin: 0;
				margin-top: $gap;

				background-color: color(white);
				transition: background-color $animationDuration;

				p {
					margin: 0;
					padding: 0;
					line-height: 1.4;
				}
			}
		}
	}

	.bio {
		max-width: 800px;

		img {
			width: 100%;
			height: auto;

			@media (max-width: bp(nav)) {
				margin-bottom: $padding--md;
			}
		}
	}
}
