.blog-entry {
	display: flex;
	align-items: flex-start;
	padding: $padding--lg 0;

	@media (max-width: bp(nav)) {
		flex-direction: column;
		padding: $padding--md 0;
	}

	&:not(:last-of-type) {
		border-bottom: 1px solid color(accent);
	}

	.thumbnail {
		width: 35%;

		@media (max-width: bp(nav)) {
			width: 100%;
			margin-bottom: $padding--sm;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.inner {
		width: 65%;
		padding-left: $padding--md;

		@media (max-width: bp(nav)) {
			width: 100%;
			padding-left: 0;
		}

		p.title3 {
			margin-bottom: 5px;
		}

		a:not(.underline-link) {
			text-decoration: none;
		}
	}
}

.blog-posts {
	padding: 0;

	.cont--lg {
		background: color(grey);
		padding: 0 $padding--lg;

		@media (max-width: bp(nav)) {
			padding: 0 $padding--sm;
		}
	}
}

.single .first-img {
	margin-bottom: $padding--sm;
}

.blog {
	.navigation {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-top: $paddingMain;

		a,
		.current {
			color: color(white);
			background: color(accent);
			padding: 5px 10px;
			text-decoration: none;
			font-size: 1.6rem;
		}

		.current {
			background-color: color(accent, orange);
		}
	}
}

.blog-hero {
	flex-direction: column;
	padding: 0;
	margin-bottom: $padding--md;

	@media (max-width: bp(nav)) {
		margin-bottom: $padding--sm;
	}

	.img {
		width: 100%;
		height: 400px;
		background-size: cover;
		background-position: bottom center;
		margin-bottom: $padding--md;

		@media (max-width: bp(nav)) {
			height: 200px;
		}
	}

	.cont {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
}

.single {
	.blog-content {
		padding: 0;

		.cont--lg {
			background: color(grey);
			padding: $padding--lg;

			@media (max-width: bp(nav)) {
				padding: $padding--sm;
			}

			p {
				width: 100%;
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.column-layout .col--two-thirds {
		width: 100%;
		padding-right: $padding--md;

		@media (max-width: bp(nav)) {
			padding-right: 0;
		}
	}

	.column-layout .col.col--third {
		justify-content: flex-start;
		width: 300px;
		flex: none;

		@media (max-width: bp(nav)) {
			width: 100%;
			margin: $padding--md 0;
		}

		p {
			margin-bottom: $gap;
		}

		a {
			@include p-font-styles;
			color: color(accent);
			width: 100%;
			display: block;
			margin-bottom: $gap;
		}
	}
}
