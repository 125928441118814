.page-template-custom-homes-template,
.page-template-communities-template {
	.builder-hero-section {
		.jump-nav {
			position: absolute;

			top: 0;
			left: 0;
			width: 100%;
			background: rgba(0, 0, 0, 0.5);
			z-index: 9;

			@media (max-width: bp(nav)) {
				display: none;
			}

			.submenu-toggle {
				display: none;
				margin: 0;
				text-align: right;
				text-transform: uppercase;
				color: color(white);

				@media (max-width: bp(nav)) {
					display: block;
				}
			}

			.cont {
				padding-top: $gap;
				padding-bottom: $gap;
			}

			ul {
				display: flex;
				align-items: center;
				justify-content: center;

				@media (max-width: bp(nav)) {
					flex-direction: column;
					width: 100%;
					align-items: flex-end;
					max-height: 0;
					overflow: hidden;
					transition: max-height 0.5s;
				}

				li {
					a {
						color: color(white);
						text-decoration: none;
						letter-spacing: 1.5px;
						padding: 0 15px;

						@media (max-width: bp(nav)) {
							padding: 0;
						}
					}
				}
			}

			&.active ul {
				max-height: 500px;
			}
		}

		h1 {
			max-width: 700px;
			margin: 0 auto $padding--md;
		}
	}

	.steps-section {
		background: color(grey);
		padding-top: 0;

		h2 {
			margin: $padding--md 0;
			font-family: $bodyFont;
			color: color(accent);
			letter-spacing: 1.5px;
		}

		.title3 {
			margin: 0 0 $padding--md;
			font-family: $bodyFont;
			color: color(accent);
			letter-spacing: 1.5px;
		}

		.swiper-section {
			background: color(white);
			padding: $padding--md 0;

			.swiper-pagination {
				width: 100%;
				position: relative !important;
				padding: 0 0 $padding--sm;
				display: flex;
				align-items: center;
				justify-content: center;
				bottom: auto;
				top: auto;
				z-index: 9;

				.swiper-pagination-bullet {
					margin: 0 10px;
					width: 35px;
					height: 35px;
					opacity: 1;
					background-color: rgba(255, 255, 255, 0);
					border: 1px solid color(accent);
					transition: all 0.5s;

					&:after {
						content: "0";
						display: block;
						color: color(accent);
						font-size: 24px;
						transform: translate(0, -2px);
						transition: all 0.5s;
					}

					&:nth-of-type(1):after {
						content: "1";
					}
					&:nth-of-type(2):after {
						content: "2";
					}
					&:nth-of-type(3):after {
						content: "3";
					}
					&:nth-of-type(4):after {
						content: "4";
					}
					&:nth-of-type(5):after {
						content: "5";
					}
					&:nth-of-type(6):after {
						content: "6";
					}

					&.swiper-pagination-bullet-active {
						background: color(accent);
						color: color(white);

						&:after {
							color: color(white);
						}
					}
				}
			}

			.column-layout {
				h3 {
					text-align: left;
				}

				.col {
					&:nth-of-type(1) {
						width: 55%;

						@media (max-width: bp(nav)) {
							width: 100%;
						}
					}

					&:nth-of-type(2) {
						width: 45%;
						padding: 0 $padding--md;

						@media (max-width: bp(nav)) {
							width: 100%;
							padding: $padding--md $padding--sm 0;
						}
					}
				}
			}
		}
	}

	.work-section {
		.tab-nav {
			justify-content: center;
			margin-bottom: $padding--md;

			@media (max-width: bp(nav)) {
				//flex-direction: row;
			}

			.tab-link {
				width: auto;
				flex: none;
				padding: 0;

				@media (max-width: bp(nav)) {
					display: none;
				}

				&:not(:last-of-type) {
					margin-right: $padding--sm;

					@media (max-width: bp(nav)) {
						margin-right: 0;
						margin-bottom: $gap;
					}
				}

				p {
					margin: 0;
				}
			}
		}

		.btn.center {
			margin: $padding--md auto;
		}
	}

	.bottom-section {
		background: color(grey);
	}

	// ----------------------------------- community specific sections
	.site-plan-section {
		background: color(grey);

		img {
			max-width: 700px;
			width: 100%;
			height: auto;
			margin: $padding--md auto 0;
			display: block;
		}
	}

	.location-section {
		[data-map] {
			width: 100%;
			min-height: 400px;
			height: 70vh;
			margin: $padding--md auto 0;

			@media (max-width: bp(nav)) {
				height: 50vh;
			}
		}

		.info-window {
			width: 300px;
			//height: 100px;
			padding: 10px;
			border: 2px solid black;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			p {
				margin: 0 0 5px 0;
				line-height: 1.2;
				text-align: center;
			}

			.btn {
				margin: 10px auto;
			}
		}

		.gm-style {
			.gm-style-iw-d {
				overflow: auto !important;
			}

			.gm-style-iw-c {
				padding: 0;
				border-radius: 0;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
			}

			[aria-label="Close"] {
				width: 20px !important;
				height: 20px !important;
				top: 0 !important;
				right: 0 !important;
				display: flex !important;
				align-items: center;
				justify-content: center;
				border: 1px solid black !important;

				span {
					margin: 0 !important;
				}
			}
		}
	}

	.map-section {
		.key {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: $padding--sm 0;

			.key-item {
				cursor: pointer;

				p {
					transition: color $animationDuration;

					@media (max-width: bp(nav)) {
						font-size: 1.4rem;
					}
				}

				&.active,
				&:hover {
					p {
						color: color(accent);
						text-decoration: underline;
					}
				}

				&:not(:last-of-type) {
					margin-right: $padding--sm;
				}
			}
		}

		.the-map {
			margin-bottom: $padding--sm;
		}

		.iw {
			padding: 10px 40px;

			@media (max-width: bp(nav)) {
				padding: 10px 30px;
			}

			p.title3 {
				@media (max-width: bp(nav)) {
					font-size: 1.6rem;
					text-align: center;
					margin-bottom: 15px;
				}
			}

			.btn {
				text-transform: uppercase;
			}
		}

		.gm-style-iw-chr {
			margin-bottom: -25px;
		}

		.gm-style {
			.gm-style-iw-d {
				overflow: auto !important;
			}

			.gm-style-iw-c {
				padding: 0;
				border-radius: 0;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
			}

			[aria-label="Close"] {
				width: 26px !important;
				height: 26px !important;
				top: 0 !important;
				right: 0 !important;
				display: flex !important;
				align-items: center;
				justify-content: center;
				border: 1px solid black !important;

				span {
					margin: 0 !important;
				}
			}
		}
	}
}

.page-template-communities-template {
	.builder-hero-section h1 {
		margin: 0 0 1rem 0;
	}

	.steps-section {
		h2 {
			margin-bottom: 0;
		}
	}
}

#schedule-an-appointment {
	max-width: 800px;
	width: 100%;

	.nf-form-cont {
		display: flex;
		flex-direction: column;

		.nf-form-title {
			h3 {
				font-family: $bodyFont;
				text-transform: uppercase;
				font-size: 1.8rem;
				letter-spacing: 1.5px;
			}
		}

		.nf-form-wrap {
			width: 100%;

			.nf-before-form-content {
				display: none;
			}

			.nf-form-content {
				padding: 0;
			}

			nf-fields-wrap {
				display: flex;
				flex-wrap: wrap;
			}

			nf-field {
				width: 100%;
				display: flex;

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				&:nth-of-type(8) {
					display: none;
				}

				.nf-field-container {
					width: 100%;
					margin-bottom: $gap;
				}

				input,
				textarea {
					border: none;
					background: color(grey);
					letter-spacing: 1.5px;
					width: 100%;
					font-family: $bodyFont;
				}

				textarea {
					height: 100px;
					font-family: $bodyFont;
				}

				.submit-container {
					input {
						background: color(white);
						color: color(accent);
						text-align: right;
						height: auto;
						padding: 0;
					}
				}
			}
		}
	}
}
