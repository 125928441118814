.page-template-cabinet-template {
	section {
		background: color(grey);
	}

	.content-section {
		padding: 0;

		.column-layout {
			background: color(white);
			padding: $padding--lg;

			@media (max-width: bp(nav)) {
				padding: $padding--md;
			}

			.col {
				&:nth-of-type(1) {
					width: calc(100% - 380px);
					padding-right: $padding--md;

					@media (max-width: bp(nav)) {
						width: 100%;
						padding-right: 0;
					}
				}

				&:nth-of-type(2) {
					width: 380px;
					flex: none;

					@media (max-width: bp(nav)) {
						width: 100%;
					}

					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}
