/****************************************

FOOTER

****************************************/

footer {
	@include paddingTopBottomMd;

	.logo {
		margin-bottom: $padding--lg;

		@media (max-width: bp(nav)) {
			margin-bottom: $padding--sm;
		}
	}

	.column-layout.inital {
		.col {
			width: 100%;
			width: 225px;
			flex: none;

			@media (max-width: bp(nav)) {
				margin-bottom: $padding--md;
			}

			&:nth-of-type(1) {
				display: flex;
				flex-direction: column;
				align-items: space-between;
				flex: none;
			}

			&:nth-of-type(2) {
			}

			a {
				text-decoration: none;
			}

			p {
				margin: 0;
				line-height: 1.4;

				&.title {
					font-weight: bold;
					margin-bottom: 5px;
				}
			}

			&:last-of-type {
				width: 100%;
				flex: 1;

				@media (max-width: bp(nav)) {
					width: 100%;
				}
			}
		}
	}

	p.disclaimer {
		color: color(accent);
	}

	.nf-form-cont {
		display: flex;
		flex-direction: column;

		.nf-form-title {
			h3 {
				font-family: $bodyFont;
				text-transform: uppercase;
				font-size: 1.8rem;
				letter-spacing: 1.5px;
			}
		}

		.nf-form-wrap {
			width: 100%;

			.nf-before-form-content {
				display: none;
			}

			.nf-form-content {
				padding: 0;
			}

			nf-fields-wrap {
				display: flex;
				flex-wrap: wrap;
			}

			nf-field {
				width: calc((100% - #{$gap * 2}) / 3);
				display: flex;

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				&:not(:nth-child(3n + 3)) {
					margin-right: $gap;

					@media (max-width: bp(nav)) {
						margin-right: 0;
					}
				}

				.nf-field-container {
					width: 100%;
					margin-bottom: $gap;
				}

				input,
				textarea {
					border: none;
					background: color(grey);
					letter-spacing: 1.5px;
					width: 100%;
					font-family: $bodyFont;
				}

				textarea {
					height: 100px;
					font-family: $bodyFont;
				}

				.submit-container {
					input {
						background: color(white);
						color: color(accent);
						text-align: right;
						height: auto;
						padding: 0;
					}
				}
			}
		}
	}

	.column-layout.update {
		align-items: center;

		.col {
			width: 100%;
			justify-content: center;
			align-items: flex-start;
			display: flex;

			&:nth-of-type(1),
			&:nth-of-type(2),
			&:nth-of-type(3) {
				width: 225px;
				flex: none;
			}

			&:nth-of-type(4) {
				align-items: center;

				a {
					font-size: 2rem;
				}
			}

			&:nth-of-type(5) {
				width: 200px;
				flex: none;
				align-items: center;

				.social {
					margin-bottom: $padding--sm;
				}
			}

			@media (max-width: bp(nav)) {
				margin-bottom: $padding--md;
			}

			.logo {
				margin: 0;
			}

			a {
				text-decoration: none;
			}

			a.btn {
				text-transform: uppercase;
			}

			p {
				margin: 0;
				line-height: 1.4;

				&.title {
					font-weight: bold;
					margin-bottom: 5px;
				}
			}
		}
	}
}

#get-in-touch {
	.nf-form-cont {
		display: flex;
		flex-direction: column;

		.nf-form-title {
			h3 {
				font-family: $bodyFont;
				text-transform: uppercase;
				font-size: 1.8rem;
				letter-spacing: 1.5px;
			}
		}

		.nf-form-wrap {
			width: 100%;

			.nf-before-form-content {
				display: none;
			}

			.nf-form-content {
				padding: 0;
			}

			nf-fields-wrap {
				display: flex;
				flex-direction: column;
			}

			nf-field {
				display: flex;

				@media (max-width: bp(nav)) {
					width: 100%;
				}

				.nf-field-container {
					width: 100%;
					margin-bottom: $gap;
				}

				input,
				textarea {
					border: none;
					background: color(grey);
					letter-spacing: 1.5px;
					width: 100%;
					font-family: $bodyFont;
				}

				textarea {
					height: 100px;
					font-family: $bodyFont;
				}

				.submit-container {
					input {
						background: color(white);
						color: color(accent);
						text-align: right;
						height: auto;
						padding: 0;
					}
				}
			}
		}
	}
}
