.hero-section {
	padding: 0;
	display: flex;
	flex-direction: column;

	.img {
		width: 100%;
		height: 400px;
		background-size: cover;
		background-position: bottom center;
		z-index: 0;
	}

	.col:nth-of-type(1) {
		@media (max-width: bp(nav)) {
			margin-bottom: $padding--md;
		}
	}

	.cont--lg,
	.cont {
		z-index: 9;
		padding-top: $padding--lg;
		padding-bottom: $padding--lg;
	}
}

.builder-hero-section {
	padding: 0;
	display: flex;
	flex-direction: column;

	.img {
		width: 100%;
		height: 650px;
		background-size: cover;
		background-position: bottom center;
		z-index: 0;

		@media (max-width: bp(nav)) {
			height: calc(100vh - 100px);
		}
	}

	.col:nth-of-type(1) {
		@media (max-width: bp(nav)) {
			margin-bottom: $padding--md;
		}
	}

	.cont--lg,
	.cont {
		z-index: 9;
		padding-top: $padding--lg;
		padding-bottom: $padding--lg;
	}
}

.close {
	position: fixed;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	font-size: 36px;
	line-height: 0;
	display: none;
	align-items: center;
	justify-content: center;
	color: color(white);
	background: color(black);
	z-index: 99;

	@media (max-width: bp(nav)) {
		display: flex;
	}
}
